import { Button, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import React, { useState } from "react";
import { MdClose, MdDelete } from "react-icons/md";
import './style.scss'
import DialogBox from "../dialog/DialogBox";


const SelectedPdfFiles = (props) => {
    const { list, remove, show, isDialogOpen, setIsDialogOpen } = props
    // const [isDialogOpen, setIsDialogOpen] = useState(false)


    const openPDF = (base64Pdf) => {
        if (base64Pdf) {
            var pdfData = base64Pdf.includes('http')?base64Pdf:`data:application/pdf;base64,${base64Pdf}`;

            var w = window.open("");

            if (w) {
                w.document.write(
                    `<embed width="100%" height="100%" src="${pdfData}" type="application/pdf" />`
                );
            }
        }
    }
    return (
        <>
            <Grid container>
                <Grid item xs={3} sm={3} className="header">
                    <Typography textAlign="center" variant="h2">
                        Questions
                    </Typography>
                </Grid>
                <Grid item xs={3} sm={3} className="header">
                    <Typography textAlign="center" variant="h2" >
                        Answers
                    </Typography>
                </Grid>
                <Grid item xs={3} sm={2} className="header">
                    <Typography textAlign="center" variant="h2" >
                        Type
                    </Typography>
                </Grid>
            </Grid>
            {
                list?.map((e, i) => (
                    <Grid container>
                        <Grid item xs={3} sm={3} className="body">
                            <div onClick={() => openPDF(e.questionPdf)}>
                                <Typography textAlign="center" variant="subtitle1">
                                    {e.questionName}
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs={3} sm={3} className="body">
                            <div onClick={() => openPDF(e.answerPdf)}>
                                <Typography textAlign="center" variant="subtitle1" >
                                    {e.answerName}
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs={3} sm={2} className="body">
                            <Typography textAlign="center" variant="subtitle1" >
                                {e.paperType}
                            </Typography>
                        </Grid>
                        {show ? <Tooltip title="Remove">
                            <IconButton onClick={() => remove(i)}
                                size="small"
                                color="error"
                            >
                                <MdClose />
                            </IconButton>
                        </Tooltip> :
                            <>
                                <Tooltip title="Delete">
                                    <IconButton
                                        size="small"
                                        color="error"
                                        onClick={() => setIsDialogOpen(!isDialogOpen)}
                                    >
                                        <MdDelete />
                                    </IconButton>
                                </Tooltip>
                                <DialogBox
                                    isOpen={isDialogOpen}
                                    title="Do you want to delete this pdf?"
                                    setIsOpen={setIsDialogOpen}
                                    delteUserHandler={() => {remove(e,i) }}
                                />
                            </>
                        }
                    </Grid>
                ))
            }

        </>
    )
}

export default SelectedPdfFiles;