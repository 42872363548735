const Copyright = () => {
  const year = new Date().getFullYear();
  return (
    <div className="copy-right">
      <p>
        @{year}, Made by
      </p>
    </div>
  );
};

export default Copyright;
