import React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button, Card, CardContent, Chip } from '@mui/material';

const UserInfoModal = ({ open, onClose, userData }) => {
  const handleClose = () => {
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="user-info-modal-title"
      aria-describedby="user-info-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          boxShadow: 24,
          maxWidth: 800,
          width: '90%',
          outline: 'none', // Remove the default focus outline
          overflowY: 'auto', // Allow vertical scrolling if content exceeds modal height
          '&::-webkit-scrollbar': {
            width: '0.5em'
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,0.1)',
            borderRadius: '4px'
          }
        }}
      >
        <Card>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Levels and Subjects:
            </Typography>

            <TableContainer component={Paper}>
              <Table aria-label="levels and subjects table">
                <TableHead>
                  <TableRow>
                    <TableCell>Level Name</TableCell>
                    <TableCell>Subjects</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {userData?.studentLevelsSubjects['$values']?.map((value, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        {value?.levelName}
                      </TableCell>
                      <TableCell>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                          {value?.subjects?.map((subject, idx) => (
                            <Chip key={idx} label={`Subject ID: ${subject.subjectId}`} variant="outlined" />
                          ))}
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <Box sx={{ mt: 2, textAlign: 'right' }}>
              <Button onClick={handleClose} variant="contained" color="primary">
                Close
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Modal>
  );
};

export default UserInfoModal;
