// export const apiURL = "https://serapi.designders.com/api";
export const apiURL = "https://serapi.serprep.com/api";

export const api = {
  login: () => {
    return {
      url: `${apiURL}/identity/token`,
      method: "post",
    };
  },
  level: () => {
    return {
      url: `${apiURL}/Level`,
      method: "get",
    };
  },
  getLevelByProgramId: id => {
    return {
      url: `${apiURL}/Level/GetByProgramId/${id}`,
      method: 'get',
    };
  },
  levelAdd: (value, id) => {
    return {
      url: `${apiURL}/Level?LevelName=${value}&ProgramId=${id}`,
      method: "post",
    };
  },
  levelUpdate: (id, value, programId) => {
    return {
      url: `${apiURL}/Level?Id=${id}&LevelName=${value}&ProgramId=${programId}`,
      method: "put",
    };
  },
  levelDelete: (id) => {
    return {
      url: `${apiURL}/Level/${id}`,
      method: "delete",
    };
  },
  subject: () => {
    return {
      url: `${apiURL}/Subjects`,
      method: "get",
    };
  },
  getSubjectByLevelId: id => {
    return {
      url: `${apiURL}/Subjects/GetBylevelId/${id}`,
      method: 'get',
    };
  },
  subjectAdd: (subName, lvlId) => {
    return {
      url: `${apiURL}/Subjects?SubjectName=${subName}&LevelId=${lvlId}`,
      method: "post",
    };
  },
  subjectUpdate: (subId, subName, lvlId) => {
    return {
      url: `${apiURL}/Subjects?Id=${subId}&SubjectName=${subName}&LevelId=${lvlId}`,
      method: "put",
    };
  },
  subjectDelete: (id) => {
    return {
      url: `${apiURL}/Subjects/${id}`,
      method: "delete",
    };
  },
  year: () => {
    return {
      url: `${apiURL}/Years`,
      method: "get",
    };
  },
  yearAdd: (value) => {
    return {
      url: `${apiURL}/Years?YearName=${value}`,
      method: "post",
    };
  },
  yearUpdate: (id, value) => {
    return {
      url: `${apiURL}/Years?Id=${id}&YearName=${value}`,
      method: "put",
    };
  },
  yearDelete: (id) => {
    return {
      url: `${apiURL}/Years/${id}`,
      method: "delete",
    };
  },
  programs: () => {
    return {
      url: `${apiURL}/Programs`,
      method: "get",
    };
  },
  programAdd: (value) => {
    return {
      url: `${apiURL}/Programs?ProgramName=${value}`,
      method: "post",
    };
  },
  programUpdate: (id, value) => {
    return {
      url: `${apiURL}/Programs?Id=${id}&ProgramName=${value}`,
      method: "put",
    };
  },
  programDelete: (id) => {
    return {
      url: `${apiURL}/Programs/${id}`,
      method: "delete",
    };
  },
  postPaper: () => {
    return {
      url: `${apiURL}/Papers`,
      method: "post",
    };
  },
  updatePaper: () => {
    return {
      url: `${apiURL}/Papers`,
      method: "put",
    };
  },
  getPapers: () => {
    return {
      url: `${apiURL}/Papers`,
      method: 'get',
    };
  },
  deletePapers: (id) => {
    return {
      url: `${apiURL}/Papers/${id}`,
      method: 'delete',
    };
  },
  deletePaperDetail: (id) => {
    return {
      url: `${apiURL}/Papers/DeleteDetails?id=${id}`,
      method: 'delete',
    };
  },
  getVideosApproval: () => {
    return {
      url: `${apiURL}/TeachersMaterial/GetVideosApproval`,
      method: 'get',
    };
  },
  getNotesApproval: () => {
    return {
      url: `${apiURL}/TeachersMaterial/GetNotesApproval`,
      method: 'get',
    };
  },
  approveData: (id) => {
    return {
      url: `${apiURL}/TeachersMaterial/ApprovedMaterials?Id=${id}&isApproved=true`,
      method: 'put',
    };
  },
  getAllTeachers: () => {
    return {
      url: `${apiURL}/TeachersMaterial/GetAllTeachersProfile`,
      method: 'get',
    };
  },
  getAllStudents: () => {
    return {
      url: `${apiURL}/StudentsActivity/GetAllStudents`,
      method: 'get',
    };
  },
  uploadBanners: () => {
    return {
      url: `${apiURL}/Banners`,
      method: 'post',
    };
  },
  addSingleBanner: () => {
    return {
      url: `${apiURL}/Banners/BannerDetails`,
      method: 'post',
    };
  },
  updateBanners: () => {
    return {
      url: `${apiURL}/Banners/UpdateBannerDetails`,
      method: 'post',
    };
  },

  deleteBanner: (id) => {
    return {
      url: `${apiURL}/Banners/${id}`,
      method: 'delete',
    };
  },
  deleteSingleBanner: (id) => {
    return {
      url: `${apiURL}/Banners/BannerDetails/${id}`,
      method: 'delete',
    };
  },
  getBanners: () => {
    return {
      url: `${apiURL}/Banners`,
      method: 'get',
    };
  },
  getBannersByID: (id) => {
    return {
      url: `${apiURL}/Banners/${id}`,
      method: 'get',
    };
  },

  uploadHomePageBanners: () => {
    return {
      url: `${apiURL}/Banners/HomePageBanner`,
      method: 'post',
    };
  }
};


