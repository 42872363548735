import * as React from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import HomeIcon from '@mui/icons-material/Home';
import TaskIcon from '@mui/icons-material/Task';
import GroupIcon from '@mui/icons-material/Group';
import GroupsIcon from '@mui/icons-material/Groups';
import "../../styles/sidebar.scss";
import underline from "../../assets/images/logo-underline.png";
import SidebarItems from "./SidebarItems";
import { IconButton, Box } from "@mui/material";
import { IoReturnDownBack } from "react-icons/io5";
import { useNavigate, Link } from "react-router-dom";

export default function SideNav({ classes, logo, type, menuToggleHandler }) {
  const [openTeacherMaterial, setOpenTeacherMaterial] = React.useState(false);
  const [openBanners, setOpenBanners] = React.useState(false);
  const [activeIndex, setActiveIndex] = React.useState(1);

  const navigate = useNavigate();

  const handleTeacherMaterialClick = () => {
    setOpenTeacherMaterial(!openTeacherMaterial);
  };

  const handleBannersClick = () => {
    setOpenBanners(!openBanners);
  };

  return (
    <div className={`sidebar ${classes}`}>
      <Box className="toggleButton">
        <IconButton
          onClick={() => {
            menuToggleHandler();
          }}
          size="small"
          sx={{ position: 'absolute', top: '0px', right: '15px' }}
        >
          <IoReturnDownBack color="black"></IoReturnDownBack>
        </IconButton>
      </Box>
      <Box className="logo-container">
        <Link to={`/${type ? type : "presentations"}/my-library`}>
          <img src={logo} className="logo" alt="logo" />
        </Link>
        <img src={underline} className="logo-underline" alt="underline" />
      </Box>
      <ul className="menu">

        <ListItemButton onClick={() => {
          setActiveIndex(1);
          navigate('/PaperUpload/RecordList');
        }} selected={activeIndex === 1}>
          <ListItemIcon>
            <HomeIcon style={{ backgroundColor: '#2D7E83', padding: 5, borderRadius: 5, color: 'white' }} />
          </ListItemIcon>
          <ListItemText secondary="Paper Upload" sx={{ color: 'black' }} />
        </ListItemButton>

        <ListItemButton onClick={handleBannersClick}>
          <ListItemIcon>
            <InboxIcon style={{ backgroundColor: '#2D7E83', padding: 5, borderRadius: 5, color: 'white' }} />
          </ListItemIcon>
          <ListItemText secondary="Banners" />
        </ListItemButton>

        <Collapse in={openBanners} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton sx={{ pl: 4 }} onClick={() => {
              setActiveIndex(2);
              navigate('/BannerUpload');
            }} selected={activeIndex === 2}>
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary="Banner Upload" />
            </ListItemButton>
            <ListItemButton sx={{ pl: 4 }} onClick={() => {
              setActiveIndex(6);
              navigate('/HomePageBannerUpload');
            }} selected={activeIndex === 6}>
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary="Homepage Banner Upload" />
            </ListItemButton>
            <ListItemButton sx={{ pl: 4 }} onClick={() => {
              setActiveIndex(7);
              navigate('/ViewBanners');
            }} selected={activeIndex === 7}>
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary="View Banners" />
            </ListItemButton>
          </List>
        </Collapse>

        <ListItemButton onClick={handleTeacherMaterialClick}>
          <ListItemIcon>
            <InboxIcon style={{ backgroundColor: '#2D7E83', padding: 5, borderRadius: 5, color: 'white' }} />
          </ListItemIcon>
          <ListItemText secondary="Teacher Material" />
        </ListItemButton>

        <Collapse in={openTeacherMaterial} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton sx={{ pl: 4 }} onClick={() => {
              setActiveIndex(3);
              navigate('/teacherMaterial/TeachersList');
            }} selected={activeIndex === 3}>
              <ListItemIcon>
                <GroupIcon />
              </ListItemIcon>
              <ListItemText primary="All Teachers" />
            </ListItemButton>
            <ListItemButton sx={{ pl: 4 }} onClick={() => {
              setActiveIndex(4);
              navigate('/teacherMaterial/DataApprovalList');
            }} selected={activeIndex === 4}>
              <ListItemIcon>
                <TaskIcon />
              </ListItemIcon>
              <ListItemText primary="Material for Approval" />
            </ListItemButton>
          </List>
        </Collapse>

        <ListItemButton onClick={() => {
          setActiveIndex(5);
          navigate('/StudentList');
        }} selected={activeIndex === 5}>
          <ListItemIcon>
            <GroupsIcon style={{ backgroundColor: '#2D7E83', padding: 5, borderRadius: 5, color: 'white' }} />
          </ListItemIcon>
          <ListItemText secondary="All Students" sx={{ color: 'black' }} />
        </ListItemButton>

      </ul>
    </div>
  );
}
