import React,{ useState, useEffect } from "react";
// import { useSelector } from "react-redux";
import Header from "./header/Header";
import { Box, Grid } from "@mui/material";
import { Outlet } from "react-router-dom";
import Sidebar from "./sidebar/Sidebar";
import { useLocation } from "react-router-dom";
import LogoLarge from "../assets/images/logo.jpeg";
import { navData } from "./sidebar/_nav";
import '../styles/layout.scss'
import SideNav from "./sidebar/SideNav";

const MainLayout = (props) => {
  
  let type = "";
  const location = useLocation().pathname;
  const [menuToggle, setMenuToggle] = useState(false);

  // const getNav = () => {
  //   const rolesNav = navData.filter((route) => {
  //     return permitNavs.some((r) => {
  //       return r.indexOf(route.url.replace("/", "")) == 0;
  //     });
  //   });
  //   return navData;
  // };

  let sideBarItems = navData;
  let rightBarWidth = 2;
  let leftBarWidth = 2;
  let mainBarWidth = 10;
  let sidebarClass = "";
  let sidebarLogo = LogoLarge;

  const menuToggleHandler = ()=>{
    setMenuToggle((prev) => !prev);
  }

  useEffect(() => {
    setMenuToggle(false);
  }, [location])
  
  return (
    <>
      <Grid container style={{overflow:'hidden'}} className="mainBackground">
        <Grid item md={rightBarWidth} lg={rightBarWidth} xlg={rightBarWidth} className={`mainCol ${menuToggle? 'show': ''}`}>
          {/* <Sidebar
            type={type}
            items={sideBarItems}
            classes={sidebarClass}
            logo={sidebarLogo}
            menuToggleHandler={menuToggleHandler}
          /> */}
          <SideNav
                      type={type}
                      classes={sidebarClass}
                      logo={sidebarLogo}
                      menuToggleHandler={menuToggleHandler}
          
          />
        </Grid>
        <Grid style={{height:'100vh',overflow:'auto'}} item md={mainBarWidth} lg={mainBarWidth} xlg={mainBarWidth} className="mainCol">
          <Header menuToggleHandler={menuToggleHandler}/>
          <Outlet />
        </Grid>
        <Grid item md={leftBarWidth} lg={leftBarWidth} xlg={leftBarWidth} >
        </Grid>
      </Grid>
    </>
  );
};


export default MainLayout;
