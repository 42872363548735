import { forwardRef } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Button, DialogActions, DialogTitle, Grow } from "@mui/material";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";


const TransitionGrow = forwardRef(function Transition(props, ref) {
  return <Grow direction="left" ref={ref} {...props} />;
});
const style = {
  maxWidth: "500px",
  background: "white",
  boxShadow: " 0px 2px 5.5px rgba(0, 0, 0, 0.02), inset 0px -4px 24px rgba(188, 192, 221, 0.25)",
  backdropFilter: "blur(21px)",
  border: " 1.5px solid #FFFFFF",
  borderRadius: "15px",
  padding: "40px",
  display: "flex",
  alignItems: "center",
};
const buttonStyle = {
  backgroundColor: '#d80000', // You can change this color to your preference
  color: 'white', // Text color
};
function DialogBox({ children, isOpen, handleClose, setIsOpen, title,delteUserHandler }) {
  return (
    <Dialog
      fullWidth
      open={isOpen}
      TransitionComponent={TransitionGrow}
      onClose={handleClose}
      PaperProps={{
        style: style,
      }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <div className="delete-button"  >
          <Button
            onClick={delteUserHandler}
            variant="contained"
            endIcon={<AiOutlineCheck />}
            style={buttonStyle}
          >
            Yes
          </Button>
          <Button
            sx={{ mr: 2 }}
            startIcon={<AiOutlineClose />}
            onClick={() => setIsOpen(!isOpen)}
            variant="outlined"
          >
            No
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}
export default DialogBox;
