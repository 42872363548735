import React,{useState,useEffect} from 'react'
import TeacherProfileCard from '../components/TeacherProfileCard/TeacherProfileCard'
import { api } from '../interfaces/api.interface'
import httpService from '../service/http.service'
import { Box,Grid,Avatar} from '@mui/material'
import CustomizedTable from '../components/customizedTable';
import UserImage from '../assets/images/person-avatar.jpeg'


function TeachersList() {
  const [allTeachers,setAllTeachers]= useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const teacherColumns = [
    { field: 'profilePictureDataUrl', headerName: 'Profile', minWidth: 80, align:'center', renderCell:(params)=> {
      return params.value  ? <Avatar  src={UserImage} />  : <Avatar  src={UserImage} /> }},
    { field: 'userName', headerName: 'User Name', minWidth: 100,flex:1  },
    { field: 'firstName', headerName: 'First Name', minWidth: 80},
    { field: 'lastName', headerName: 'Last Name', minWidth: 80,flex:1},
    { field: 'email', headerName: 'Email', minWidth: 80},
    { field: 'isActive', headerName: 'Active', minWidth: 80},
    { field: 'phoneNumber', headerName: 'Phone', minWidth: 80},
    { field: 'a', headerName: 'Year of Experience', minWidth: 80},
    { field: '', headerName: 'Speciality', minWidth: 80},
  ]


  const getAllTeachers = () => {
    setIsLoading(true)
    httpService.call(
      api.getAllTeachers(),null,true
    ).then(res => {
      if (res?.status === 200) {
        setAllTeachers(res.data["$values"])
        console.log("Teachers====", res.data["$values"])
      }
    setIsLoading(false)
    }).catch((e) => {
      console.log(e)
     setIsLoading(false)
    })
  }

  useEffect(()=> {
    getAllTeachers()
  },[])
  return (
    <Box style={{ width: '100%', padding: '20px 0px'}}>
   
   <CustomizedTable columns={teacherColumns} data={allTeachers} loading={isLoading}/>
    {/* <Grid container >
        
        { allTeachers.map((teacher)=> <Grid item xs={12} md={6} lg={6} style={{margin:'12px 0px'}}>
          <TeacherProfileCard name={`${teacher.firstName} ${teacher.lastName}`}/>
        </Grid>
      ) }
        </Grid> */}
        </Box>
)
}

export default TeachersList