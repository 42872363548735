import axios from "axios";
// import cookie from "react-cookies";
import Cookies from "js-cookie";

const getHeaders = (isTokenUse) => {
  if(isTokenUse){
    // const token = cookie.load("token");
    // const token = Cookies.get("token");
    const token = localStorage.getItem("token");

    console.log("token==>",token)
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
  }
  const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  };
  return headers;
};

const call = (api, data,isTokenUse=false) => {
  let headers = getHeaders(isTokenUse);
  
  return axios[api.method]?.(api.url, JSON.stringify(data), { headers })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error)
    });
};

export default {
  call,
};
