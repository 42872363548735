import React, { useContext, useState } from "react";
// import { login } from "../store/actions/auth";
import { Box, Grid, Typography } from "@mui/material";
import LogoLarge from "../assets/images/logo.jpeg";
import Copyright from "../components/Copyright";
import "../styles/login.css";
// import { useMediaQuery } from "@mui/material";
import Hidden from "../components/Hidden";
import LoginForm from "./LoginForm";
import Logo from "../components/Logo";
import { api } from "../interfaces/api.interface";
import httpService from "../service/http.service";
import { AuthContext } from "../store/context/authContext";
import { uiActions } from "../store/redux/ui-slice";
import { useDispatch } from "react-redux";
import cookie from "react-cookies";
const Login = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { authenticate, isLogin } = useContext(AuthContext);
  const dispatch = useDispatch()

  const loginUserHandler = (event) => {
    setIsLoading(true)
    httpService.call(
      api.login(),
      event
    ).then(res => {
      if (res.data.succeeded) {
        setIsLoading(true)
        setIsLoading(false)
        authenticate(res.data.data)
        cookie.save("serAdmin", JSON.stringify(res.data.data));
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Authorized!",
            message: "Welcome to SER",
          })
        )
      } else {
        console.log("Login API ==>", res)
        setIsLoading(false)
      }
    }).catch((e) => {
      setIsLoading(false)
      console.log(e)
    })

  }

  return (
    <Grid container className="login-page">
      <Hidden mobile>
        <Grid item md={7} sm={12} id="login-background">
        </Grid>
      </Hidden>
      <Grid
        item
        md={5}
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Box className="login-form-container">
          <Logo logoSrc={LogoLarge} />
          <Typography component="h1" variant="h1" className="login-heading">
            Welcome To AdminPortal
          </Typography>
          <LoginForm isLoading={isLoading} loginHandler={loginUserHandler} />
          <Hidden mobile>
            {/* <Copyright /> */}
          </Hidden>
        </Box>
      </Grid>
    </Grid>
  );
};
export default Login;
