import React, { useEffect, useState, useRef } from "react";
import { Card, CircularProgress, Grid, Stack, Typography, RadioGroup, FormControlLabel, Radio, Table, TableBody, TableCell, TableHead, TableRow, IconButton, Switch } from "@mui/material";
import Button from "../components/Button";
import { Box } from "@mui/material";
import "../styles/paperUpload.css";
import { useForm, Controller } from "react-hook-form";
import SelectFields from "../components/SelectFields";
import httpService from "../service/http.service";
import { api } from "../interfaces/api.interface";
import { useDispatch, useSelector } from "react-redux";
import { setLevel, setProgram, setSubject } from "../store/redux/dataList-slice";
import { uiActions } from "../store/redux/ui-slice";
import CourseDetail from "../components/CourseDetails";
import { useLocation } from "react-router-dom";
import InputFields from "../components/InputFields";
import BannerDetails from "../components/BannerDetails";

const UpdateBanner = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [bannerData,setBannerData] = useState(null);
  const [listError, setListError] = useState(false);

  const [place, setPlace] = useState("app");
  const [isActive, setIsActive] = useState(true);

  const dispatch = useDispatch();
  const location = useLocation();
  const _id = location?.state

  const { programs, levels, subjects } = useSelector((state) => state.DataList);

  useEffect(() => {
      console.log("Banner ID ====>",location?.state)
      if(_id){
        getBannersByID(_id)
      }
  }, [location?.state]);

    
  const getBannersByID = (id) => {
    setIsLoading(true);
    httpService
      .call(api.getBannersByID(id)) 
      .then((res) => {
        if (res.status === 200) {
          const data = res?.data?.data       
          console.log("banners===>",data)
          
          setValue("program",data.programId)
          setValue("level",data.levelId)
          setValue("subject",data.subjectId)
          setBannerData(data?.details?.$values);
          setIsActive(data?.isActive)
        }
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setIsLoading(false);
      });
    }


  const defaultValues = useForm({
    defaultValues: {
      program: "",
      level: "",
      subject: "",
      title:""
    },
  });

  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    resetField,
    formState: { errors },
  } = defaultValues;

  useEffect(() => {
    getPrograms();
    getLevels();
    getSubjects();
  }, []);

  const getPrograms = () => {
    httpService
      .call(api.programs())
      .then((res) => {
        if (res.status === 200) {
          dispatch(setProgram(res.data.data["$values"]));
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getLevels = () => {
    httpService
      .call(api.level())
      .then((res) => {
        if (res?.status === 200) {
          dispatch(setLevel(res.data.data["$values"]));
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getSubjects = () => {
    httpService
      .call(api.subject())
      .then((res) => {
        if (res?.status === 200) {
          dispatch(setSubject(res.data.data["$values"]));
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };


  const deleteBanner = (id) => {
    httpService
      .call(api.deleteBanner(id))
      .then((res) => {
        if (res?.data?.succeeded) {
          console.log("Delete ==>", res.data);
          getBannersByID(id)
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    setListError(false)
  }, [bannerData])

  const onSubmitHandler = (data) => {
    console.log(bannerData)
    if( bannerData == null || bannerData?.banners?.length === 0){
      setListError(true)
      return
    }

    let params = {
      "title": "Test",
      "subjectId": data?.subject,
      "place": bannerData?.place,
      "isActive": true,
      "postBannerDetails": bannerData?.banners
    }

    console.log("data ==>", params);

    setIsLoading(true);
    httpService
      .call(api.uploadBanners(), params)
      .then((res) => {
        if (res.data.succeeded) {
          setIsLoading(false);
          dispatch(
            uiActions.showNotification({
              status: "success",
              title: "Uploaded Successfully!",
              message: "Banner uploaded successfully",
            })
          );
          reset();
        } else {
          console.log("Upload API ==>", res);
          setIsLoading(false);
        }
      })
      .catch((e) => {
        setIsLoading(false);
        console.log(e);
      });
  };

  return (
    <form style={{ display: "flex" }}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Card className="form-card">
            <Box className="form-heading">
              <Typography variant="h1">Banner:</Typography>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} className="feildInput">
                <SelectFields
                  fieldName="program"
                  label="Program"
                  control={control}
                  options={programs.map((pg) => {
                    return { label: pg.programName, value: pg.id };
                  })}
                  rules={{
                    required: "Program is required",
                  }}
                  error={errors?.program}
                  withSearch
                />
              </Grid>
              <Grid item xs={12} sm={6} className="feildInput">
                <SelectFields
                  fieldName="level"
                  label="Level"
                  control={control}
                  disabled={!watch("program")}
                  options={levels.filter((e) => e.programId === watch("program")).map((lvl) => {
                    return { label: lvl.levelName, value: lvl.id };
                  })}
                  rules={{
                    required: "Level is required",
                  }}
                  error={errors?.level}
                  withSearch
                />
              </Grid>

              <Grid item xs={12} sm={6} className="feildInput">
                <SelectFields
                  fieldName="subject"
                  label="Subject"
                  control={control}
                  disabled={!watch("level")}
                  options={subjects.filter((e) => e.levelId === watch("level")).map((sub) => {
                    return { label: sub.subjectName, value: sub.id };
                  })}
                  rules={{
                    required: "Subject is required",
                  }}
                  error={errors?.subject}
                  withSearch
                />
              </Grid>

              <Grid item xs={12} sm={6} className="feildInput">
                <InputFields
                  fieldName="title"
                  type="text"
                  label="Title"
                  control={control}
                  rules={{
                    required: "Title is required",
                  }}
                  error={errors?.title}
                />
              </Grid>

              <Grid item xs={12} sm={4} direction={'row'} alignItems={'center'} display={'flex'} sx={{top:-24,position:'relative'}}>
                <Typography variant="h1" style={{ marginRight: 5 }}>Place:</Typography>
                <RadioGroup
                  row
                  aria-label="place"
                  value={place}
                  onChange={(e) => setPlace(e.target.value)}
                >
                  <FormControlLabel value="app" control={<Radio />} label="App" />
                  <FormControlLabel value="website" control={<Radio />} label="Website" />
                
                </RadioGroup>
              </Grid>
              <Grid item xs={12} sm={2} direction={'row'} alignItems={'center'} display={'flex'} sx={{top:-24,position:'relative'}}>
              <Typography variant="h1" style={{ marginRight: 5 }}>Active:</Typography>
                 
                 <Switch
                   checked={isActive}
                   onChange={(e)=> deleteBanner(_id)}
                   inputProps={{ 'aria-label': 'controlled' }}/>
      
</Grid>

            </Grid>
            <BannerDetails data={bannerData} setData={setBannerData} bannerId={_id} />

            
                {listError && <Typography textAlign="center" variant="body2" color='error'>
                  "please fill all required data"
                </Typography>}
            <Stack direction="row" justifyContent="flex-end" sx={{ mt: 2 }}>
              <Button
                onClick={handleSubmit(onSubmitHandler)}
                type="submit"
                color="btnColor"
                variant="contained"
                disabled={isLoading}
              >
                {isLoading && <CircularProgress size={20} sx={{ marginRight: 1 }} />}
                Save
              </Button>
            </Stack>

          </Card>
        </Grid>
      </Grid>
    </form>
  );
};

export default UpdateBanner;
