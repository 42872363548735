import logo from './logo.svg';
import './App.css';
import { useRoutes } from 'react-router-dom';
import routes from './routes';
import FieldSelectorProvider from './store/context/fieldSelectore';
import AuthProvider from './store/context/authContext';
import { useSelector } from 'react-redux';
import Notification from './components/notification';
import { useState } from 'react';

function App() {
  const routing = useRoutes(routes);
  const { notification } = useSelector((state) => state.ui);
  return (
    <>
      <AuthProvider>
        <FieldSelectorProvider>
          {notification && (
            <Notification
              notification={notification}
              status={notification.status}
              title={notification.title}
              message={notification.message}
            />
          )}
          {routing}
        </FieldSelectorProvider>
      </AuthProvider>
    </>
  );

}

export default App;
