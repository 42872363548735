import { publicRoutes } from "./public.js";
import { privateRoutes } from "./private.js";
import NotFound from "../layouts/NotFound";
import BlankLayout from "../layouts/BlankLayout.jsx";
import RequireAuth from "../layouts/RequireAuth.jsx";
// import RequireAuth from "src/layouts/RequireAuth";

const routes = [
  {
    path: "/",
    element: <RequireAuth />,
    children: privateRoutes,
  },
  {
    path: "/",
    element: <BlankLayout />,
    children: publicRoutes,
  },
  {
    path: "*",
    element: <NotFound />,
  },
];

export default routes;
