import React, {createContext, useState} from 'react';

// Global sequence number, incremented every time a query is run
export const FieldSelectorContext = createContext(null);

const FieldSelectorProvider = ({children}) => {
  const [fieldSelector, setFieldSelector] = useState(null);

  const fieldSelectorHandler = val => {
    setFieldSelector(val);
  };

  const clearFieldSelector = () => {
    setFieldSelector(null);
  };

  const value = {
    fieldSelector: fieldSelector,
    fieldSelectorHandler: fieldSelectorHandler,
    clearFieldSelector:clearFieldSelector
  };

  return <FieldSelectorContext.Provider value={value}>{children}</FieldSelectorContext.Provider>;
};

export default FieldSelectorProvider;
