import React, { useEffect, useState } from "react";
import { Box, Button, Card, CircularProgress, Grid, Typography } from "@mui/material";
import Content from "../components/content/Content";
import Tables from "../components/Table";
import { headCells } from "../constant";
import RecordActionColumn from "../components/recordActionColumn/recordActionColumn";
import { useNavigate } from "react-router-dom";
import httpService from "../service/http.service";
import { api } from "../interfaces/api.interface";
import DialogBox from "../components/dialog/DialogBox";

const RecordList = () => {
    const [papers, setPapers] = useState([])
    const [isDialogOpen, setIsDialogOpen] = useState(false)
    const [deleteId, setDeleteId] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate();

    const createRecord = () => {
        navigate("/PaperUpload/CreateForm");
    }
    useEffect(() => {
        getPapersList()
    }, [])

    const getPapersList = () => {
        setIsLoading(true)
        httpService.call(api.getPapers()).then(res => {
            console.log("ressss ==>",res.data.data.papersViews?.$values)
            if (res.data.succeeded) {
                setPapers(res.data.data.papersViews?.$values);
            }
            setIsLoading(false)
        }).catch(e => {
            setIsLoading(false)
            // console.log(e)
        })
    }

    const updateHandler = (data) => {
        navigate("/PaperUpload/CreateForm", { state: data })
    }

    const deletehandler = (data) => {
        setIsDialogOpen(true)
        setDeleteId(data?.id)
    }
    const paperDelete = () => {
        console.log(deleteId)
        httpService.call(api.deletePapers(deleteId)).then(res => {
            if (res.status === 200) {
                let index = papers.findIndex((res => res.id === deleteId))
                papers.splice(index, 1)
                setIsDialogOpen(false)
            }
        }).catch((e) => {
            setIsDialogOpen(false)
        })

    }

    const extraColumn = (data) => <RecordActionColumn
        onUpdate={() => updateHandler(data)}
        deletePaper={() => deletehandler(data)}
        isDialogOpen={isDialogOpen}
        setIsDialogOpen={setIsDialogOpen} />
    return (
        <>
            <Grid container spacing={2} >
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Content
                        title="Records"
                        headerAction={
                            <Button variant="contained" color="btnColor" onClick={createRecord}>
                                Create Record
                            </Button>
                        }
                    >
                        {/* <PaperUpload /> */}
                        {isLoading ? (
                            <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '60vh' }}>
                                <CircularProgress size={50} sx={{ margin: 5 }} />
                                <Typography component="h1" variant="h1">
                                    Loading....
                                </Typography>
                            </Box>
                        ) : (<Box sx={{ margin: '50px auto' }}>
                            <Card className="form-card">
                                <Tables
                                    headCells={headCells}
                                    rows={papers}
                                    setRows={setPapers}
                                    getPapersList={getPapersList}
                                    items={["Program", "Level", "Subject", "Year", "Month"]}
                                    extraColumnName="Action"
                                    extraColumn={extraColumn}
                                />
                            </Card>
                        </Box>)}

                    </Content>
                </Grid>
                <DialogBox
                    isOpen={isDialogOpen}
                    title="Do you want to delete this paper?"
                    setIsOpen={setIsDialogOpen}
                    delteUserHandler={paperDelete}
                />
            </Grid>
        </>
    )

}
export default RecordList;