import React, { useContext, useState } from "react";
import { Card, CircularProgress, Grid, Stack, Typography } from "@mui/material";
import Button from "../components/Button";
import { Box } from "@mui/material";
import "../styles/paperUpload.css";
import InputFields from "../components/InputFields";
import { useForm } from "react-hook-form";
import SelectFields from "../components/SelectFields";
import Content from "../components/content/Content";
import { FieldSelectorContext } from "../store/context/fieldSelectore";
import httpService from "../service/http.service";
import { api } from "../interfaces/api.interface";
import { useDispatch, useSelector } from "react-redux";
import { addLevel, addProgram, addSubject, addYear, deleteLevel, deleteProgram, deleteSubject, deleteYear, updateLevel, updateProgram, updateSubject, updateYear } from "../store/redux/dataList-slice";
import { uiActions } from "../store/redux/ui-slice";

const FieldHandler = () => {
  const fieldCtxt = useContext(FieldSelectorContext)
  const field = fieldCtxt.fieldSelector

  const dispatch = useDispatch()
  const { programs, levels, subjects } = useSelector(state => state.DataList)

  const [isLoading, setIsLoading] = useState(false)

  const defaultValues = useForm({
    defaultValues: {
      program: "",
      selectedProgram: "",
      level: "",
      selectedLevel: "",
      subject: "",
      selectedSubject: "",
      year: "",
      selectedyear: "",
    },
  });

  const {
    control,
    handleSubmit,
    setError,
    clearErrors,
    watch,
    reset,
    resetField,
    formState: { errors },
  } = defaultValues;

  const programUpdate = (data) => {
    setIsLoading(true)
    httpService.call(
      api.programUpdate(data.selectedProgram, data.program)
    ).then(res => {
      if (res.data.succeeded) {
        dispatch(updateProgram(res.data.data))
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Authorized!",
            message: "The program has been updated.",
          })
        )
        setIsLoading(false)
      }
    }).catch((e) => {
      console.log(e)
      setIsLoading(false)
    })
  }

  const programAdd = (data) => {
    setIsLoading(true)
    httpService.call(
      api.programAdd(data.program)
    ).then(res => {
      console.log(res)
      if (res.data.succeeded) {
        dispatch(addProgram(res.data.data))
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Authorized!",
            message: "The program has been added.",
          })
        )
        setIsLoading(false)
      }
    }).catch((e) => {
      console.log(e)
      setIsLoading(false)
    })
  }
  const programDelete = (data) => {
    setIsLoading(true)
    httpService.call(
      api.programDelete(data.selectedProgram)
    ).then(res => {
      if (res.data.succeeded) {
        let id = data.selectedProgram
        dispatch(deleteProgram(id))
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Authorized!",
            message: "The program has been deleted.",
          })
        )
        setIsLoading(false)
      }
    }).catch((e) => {
      console.log(e)
      setIsLoading(false)
    })
  }

  const levelUpdata = (data) => {
    setIsLoading(true)
    console.log(data)
    httpService.call(
      api.levelUpdate(data.selectedLevel, data.level, data.selectedProgram)
    ).then(res => {
      if (res.data.succeeded) {
        dispatch(updateLevel(res.data.data))
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Authorized!",
            message: "The level has been updated.",
          })
        )
        setIsLoading(false)
      }
    }).catch((e) => {
      console.log(e)
      setIsLoading(false)
    })
  }

  const levelAdd = (data) => {
    setIsLoading(true)
    httpService.call(
      api.levelAdd(data.level, data.selectedProgram)
    ).then(res => {
      if (res.data.succeeded) {
        dispatch(addLevel(res.data.data))
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Authorized!",
            message: "The level has been added.",
          })
        )
        setIsLoading(false)
      }
    }).catch((e) => {
      console.log(e)
      setIsLoading(false)
    })
  }
  const levelDelete = (data) => {
    setIsLoading(true)
    httpService.call(
      api.levelDelete(data.selectedLevel)
    ).then(res => {
      if (res.data.succeeded) {
        let id = data.selectedLevel
        dispatch(deleteLevel(id))
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Authorized!",
            message: "The level has been deleted.",
          })
        )
        setIsLoading(false)
      }
    }).catch((e) => {
      console.log(e)
      setIsLoading(false)
    })
  }

  const subjectAdd = (data) => {
    setIsLoading(true)
    httpService.call(
      api.subjectAdd(data.subject, data.selectedLevel)
    ).then(res => {
      if (res.data.succeeded) {
        dispatch(addSubject(res.data.data))
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Authorized!",
            message: "The subject has been added.",
          })
        )
        setIsLoading(false)
      }
    }).catch((e) => {
      console.log(e)
      setIsLoading(false)
    })
  }
  const subjectUpdate = (data) => {
    setIsLoading(true)
    httpService.call(
      api.subjectUpdate(data.selectedSubject, data.subject, data.selectedLevel)
    ).then(res => {
      if (res.data.succeeded) {
        dispatch(updateSubject(res.data.data))
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Authorized!",
            message: "The subject has been updated.",
          })
        )
        setIsLoading(false)
      }
    }).catch((e) => {
      console.log(e)
      setIsLoading(false)
    })
  }

  const subjectDelete = (data) => {
    setIsLoading(true)
    httpService.call(
      api.subjectDelete(data.selectedSubject)
    ).then(res => {

      if (res.data.succeeded) {
        let id = data.selectedSubject
        dispatch(deleteSubject(id))
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Authorized!",
            message: "The subject has been deleted.",
          })
        )
        setIsLoading(false)
      }
    }).catch((e) => {
      console.log(e)
      setIsLoading(false)
    })
  }


  const onSubmitHandler = (data) => {

    if ((data.program || data.selectedProgram) && !data.level) {
      if (field.action == 'edit') {
        programUpdate(data)
      }
      if (field.action == 'add') {
        programAdd(data)
      }
      if (field.action == 'delete' && field.cell !== 'levels') {
        programDelete(data)
      }
    }

    if ((data.level || data.selectedLevel) && !data.subject) {
      if (field.action == 'edit') {
        levelUpdata(data)
      }
      if (field.action == 'add') {
        levelAdd(data)
      }
      if (field.action == 'delete' && field.cell !== 'subjects') {
        levelDelete(data)
      }
    }

    if (data.subject && (data.level || data.selectedLevel)) {
      if (field.action == 'edit') {
        subjectUpdate(data)
      }
      if (field.action == 'add') {
        subjectAdd(data)
      }
    }

    if (data.selectedSubject && field.action == 'delete') {
      subjectDelete(data)
    }

  }
  return (
    <Content title={`${field.action == 'add' ? "Create New" : field.action[0].toUpperCase() + field.action.slice(1)} ${field.cell[0].toUpperCase() + field.cell.slice(1)}`}>
      <form>
        <Card className="form-card">
          <Box className="form-heading">
            <Typography variant="h1">{`${field.cell[0].toUpperCase() + field.cell.slice(1)}`} Details:</Typography>
          </Box>
          <Grid container spacing={2}>
            {/* =================== PROGRAM FEILDS ================= */}
            {field.cell == 'programs' ?
              <>
                {(field.action == 'edit' || field.action == 'delete') && <Grid item xs={12}>
                  <SelectFields
                    fieldName="selectedProgram"
                    label="Program"
                    control={control}
                    options={programs.map((pg) => {
                      return { label: pg.programName, value: pg.id };
                    })}
                    rules={{
                      required: "Program is required",
                    }}
                    error={errors?.selectedProgram}
                    withSearch
                  />
                </Grid>}
                {field.action !== 'delete' && <Grid item xs={12}>
                  <InputFields
                    fieldName="program"
                    type="text"
                    label="Program"
                    control={control}
                    rules={{
                      required: "Program is required",
                    }}
                    error={errors?.program}
                  />
                </Grid>}
              </>
              : null}

            {/* =================== LEVEL FEILDS ================= */}
            {field.cell == 'levels' ?
              <>

                <Grid item xs={12}>
                  <SelectFields
                    fieldName="selectedProgram"
                    label="Program"
                    control={control}
                    options={programs.map((pg) => {
                      return { label: pg.programName, value: pg.id };
                    })}
                    rules={{
                      required: "Program is required",
                    }}
                    error={errors?.selectedProgram}
                    withSearch
                  />
                </Grid>
                {(field.action == 'edit' || field.action == 'delete') && <Grid item xs={12}>
                  <SelectFields
                    fieldName="selectedLevel"
                    label="Level"
                    control={control}
                    disabled={!watch("selectedProgram")}
                    options={levels.filter(e => e.programId == watch("selectedProgram")).map((lvl) => {
                      return { label: lvl.levelName, value: lvl.id };
                    })}
                    rules={{
                      required: "Level is required",
                    }}
                    error={errors?.selectedLevel}
                    withSearch
                  />
                </Grid>}
                {field.action !== 'delete' &&
                  <Grid item xs={12}>
                    <InputFields
                      fieldName="level"
                      type="text"
                      label="Level"
                      control={control}
                      rules={{
                        required: "Level is required",
                      }}
                      error={errors?.level}
                    />
                  </Grid>
                }
              </>
              : null}

            {/* =================== SUBJECTS FEILDS ================= */}
            {field.cell == 'subjects' ?
              <>

                <Grid item xs={12}>
                  <SelectFields
                    fieldName="selectedLevel"
                    label="Level"
                    control={control}
                    options={levels.map((lvl) => {
                      return { label: lvl.levelName, value: lvl.id };
                    })}
                    rules={{
                      required: "Level is required",
                    }}
                    error={errors?.selectedLevel}
                    withSearch
                  />
                </Grid>

                {(field.action == 'edit' || field.action == 'delete') && <Grid item xs={12}>
                  <SelectFields
                    fieldName="selectedSubject"
                    label="Subject"
                    control={control}
                    disabled={!watch("selectedLevel")}
                    options={subjects.filter(e => e.levelId == watch("selectedLevel")).map((sub) => {
                      return { label: sub.subjectName, value: sub.id };
                    })}
                    rules={{
                      required: "Subject is required",
                    }}
                    error={errors?.selectedSubject}
                    withSearch
                  />
                </Grid>}
                {field.action !== 'delete' &&
                  <Grid item xs={12}>
                    <InputFields
                      fieldName="subject"
                      type="text"
                      label="Subject"
                      control={control}
                      rules={{
                        required: "Subject is required",
                      }}
                      error={errors?.subject}
                    />
                  </Grid>
                }
              </>
              : null}
          </Grid>

        </Card>
        <Stack direction="row" justifyContent="flex-end">
          <Button
            onClick={handleSubmit(onSubmitHandler)}
            type="submit"
            variant="contained"
            color="btnColor"
            disabled={isLoading}
          >
            {isLoading && (
              <CircularProgress size={20} sx={{ marginRight: 1 }} />
            )}
            Save
          </Button>
        </Stack>
      </form>
    </Content>
    // </Card>
  );
};

export default FieldHandler;