import React, { useState } from "react";
import {
  Menu,
  MenuItem,
  Button,
  IconButton,
  Typography,
  Tooltip,
} from "@mui/material";
import './dropdown.scss'

const DropDown = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  let renderButton = null;

  //only button icon
  if (props.dropText) {
    renderButton = (
      <Button
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={props.dropIcon}
        startIcon={props.startIcon}
        className="Button"
        color={props.color}
        fullWidth={props.fullWidth ? true : false}
        disabled={props.disabled ? true : false}
      >
        <Typography sx={{ color: "#fff" }}>{props.dropText}</Typography>
      </Button>
    );
  } else {
    renderButton = (
      <Tooltip title={props.tooltip}>
        <IconButton
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          variant="outlined"
          onClick={handleClick}
          className={`IconButton ${props.iconClass}`}
          disabled={props.disabled}
          size="small"
        >
          {props.dropIcon}
        </IconButton>
      </Tooltip>
    );
  }
  return (
    <>
      {renderButton}
      <Menu
        id="basic-menu"
        className="dropdown"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {props.items.map((item, index) => (
          <MenuItem
            key={index}
            className="cards-menu-items"
            onClick={() => {
              if (item.onClick) {
                item.onClick(props.id, props.data);
              }
              handleClose();
            }}
          >
            {item.icon} {item.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
export default React.memo(DropDown);
