import React,{useState,useEffect} from 'react'
import TeacherProfileCard from '../components/TeacherProfileCard/TeacherProfileCard'
import { api } from '../interfaces/api.interface'
import httpService from '../service/http.service'
import { Box,Grid,Avatar, Button} from '@mui/material'
import CustomizedTable from '../components/customizedTable';
import UserImage from '../assets/images/person-avatar.jpeg'
import UserInfoModal from '../components/UserInfoModal'


function StudentList() {
  const [allTeachers,setAllTeachers]= useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [userData, setUserData] = useState(null);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const onClickHandler = (data)=> {
    console.log(data)
    setUserData(data)
  }

useEffect(()=>{
  if(userData){
    setOpenModal(true)
  }
},[userData])
  const teacherColumns = [
    { field: 'profilePictureDataUrl', headerName: 'Profile', minWidth: 50, align:'center', renderCell:(params)=> {
      // console.log(params.value)
      return params.value  ? <Avatar  src={params.value} />  : <Avatar  src={UserImage} /> }},
    { field: 'userName', headerName: 'User Name', minWidth: 80, },
    { field: 'firstName', headerName: 'First Name', minWidth: 80},
    { field: 'lastName', headerName: 'Last Name', minWidth: 80},
    { field: 'email', headerName: 'Email', minWidth: 80,flex:1},
    { field: 'isActive', headerName: 'Active', minWidth: 40},
    { field: 'phoneNumber', headerName: 'Phone', minWidth: 80},   
    { field: 'programName', headerName: 'Program', minWidth: 80,align:'center',renderCell:(params)=> {
      // console.log(params?.row)
    return params.value  ? <Button variant="contained" onClick={()=>onClickHandler(params?.row)}>View</Button>  :  <Button variant="contained" disabled >View</Button>}}
  ]


  const getAllTeachers = () => {
    setIsLoading(true)
    httpService.call(api.getAllStudents(),null,true).then(res => {
      if (res?.status === 200) {
        setAllTeachers(res?.data?.data["$values"])
        console.log("students ==>", res?.data?.data["$values"])
      }
    setIsLoading(false)
    }).catch((e) => {
      console.log(e)
     setIsLoading(false)
    })
  }

  useEffect(()=> {
    getAllTeachers()
  },[])
  return (
    <Box style={{ width: '100%', padding: '20px 0px'}}>
   
   <CustomizedTable columns={teacherColumns} data={allTeachers} loading={isLoading}/>
   <UserInfoModal open={openModal} onClose={handleCloseModal} userData={userData} />
    </Box>
)
}

export default StudentList