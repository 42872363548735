import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Box,
  Typography,
  Toolbar,
  Button,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import dayjs from 'dayjs';

const BannersTable = ({ banners, editBannerHandler, removeBannerHandler}) => {
  return (
    <Box sx={{ mt: 4 }}>
      <Paper>
        <TableContainer>
          <Table>
          <TableHead>
              <TableRow sx={{ backgroundColor: '#f5f5f5' }}>
                <TableCell>
                  <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                    Banner Name
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                    Link
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                    From Date
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                    To Date
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                    Start Time
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                    End Time
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                    Image
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                    Actions
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>            
            <TableBody>
              {banners.map((banner, index) => (
                <TableRow key={index} hover>
                  <TableCell>{banner?.title || banner?.name}</TableCell>
                  <TableCell>{banner.link}</TableCell>
                  <TableCell>{dayjs(banner.startDate)?.format('DD/MM/YYYY')}</TableCell>
                  <TableCell>{dayjs(banner.endDate)?.format('DD/MM/YYYY')}</TableCell>
                  <TableCell>{banner.startTime}</TableCell>
                  <TableCell>{banner.endTime}</TableCell>
                  <TableCell>
                    <img src={banner?.image} style={{ width: 50, height: 30 }} alt="Banner" />
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={() => editBannerHandler(index)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => removeBannerHandler(index)} sx={{color:'tomato'}}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
};

export default BannersTable;
