// import React, { useContext, useEffect } from "react";
// import MainLayout from "./MainLayout";
// import { AuthContext } from "../store/context/authContext";
// import { Navigate, useLocation, useNavigate } from "react-router-dom";

// const RequireAuth = () => {
//   const { isLogin } = useContext(AuthContext);

//   const location = useLocation();
//   // const navigate = useNavigate();
//   // const dispatch = useDispatch();

//   // const getModuleUrl = (url) => {
//   //   return url.split("/").at(1);
//   // };

//   // useEffect(() => {
//   //   if (isLogin) {
//   //     const jwt = user.jwt;
//   //     const userData = jsonwebtoken.decode(jwt);
//   //     dispatch(setUserData(userData));
//   //   }
//   // }, [user]);

//   // useEffect(() => {
//   //   if (role) {
//   //     const url = getModuleUrl(location.pathname);
//   //     const isHaveAccess = getRolesPermission(role, url);

//   //     if (!isHaveAccess) {
//   //       const entry = getRolesEntry(role);
//   //       navigate(`/${entry}`);
//   //     }
//   //   }
//   // }, [role, location]);

//   // useEffect(() => {

//   // }, [isLogin])

//   // useEffect(() => {
//   //   if (!isLogin) {
//   //     <Navigate
//   //       to="/login"
//   //       state={{
//   //         visitedLocation: location.pathname,
//   //       }}
//   //       replace
//   //     />
//   //   }
//   //   return;
//   // }, [isLogin])





//   console.log(isLogin)
//   return (
//     <>
//       {isLogin && <MainLayout />}
//       {!isLogin && <Navigate
//         to="/login"
//         state={{
//           visitedLocation: location.pathname,
//         }}
//         replace
//       />}
//     </>
//   )
//   // return <MainLayout />
//   // return (
//   //   <>
//   //     {isLogin && <MainLayout />}
//   //     {!isLogin && <Navigate to="/login" />}
//   //   </>);
// };

// export default RequireAuth;



import { useContext } from "react";
import React, { Outlet } from "react-router-dom";
import {useAuth} from "../store/context/authContext";
import MainLayout from "./MainLayout";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

const RequireAuth = () => {

  const { isLogin } = useAuth();
  const token = localStorage.getItem('token')
  console.log('isLogin', isLogin)
  const location = useLocation();
  if (!token) {
    return (<Navigate
      to="/login"
      state={{
        visitedLocation: location.pathname,
      }}
      replace
    />)
  }
  return (
    <main>
      <MainLayout />
    </main>
  );
};

export default RequireAuth;
