import React from "react";
//Icons
import {
  IoHome,
} from "react-icons/io5";
import { BsFillEyeFill } from "react-icons/bs";

export const navData = [
  {
    label: "Paper Upload",
    url: "/PaperUpload",
    icon: <IoHome />,
    subNav: [
      {
        icon: <BsFillEyeFill />,
        label: "View",
        url: `/RecordList`,
        onClick: (setState) => {
          setState(true);
        },
      },
    ],
  },
  {
    label: "Teachers Material",
    url: "/PaperUpload",
    icon: <IoHome />,
    subNav: [
      {
        icon: <BsFillEyeFill />,
        label: "View",
        url: `/RecordList`,
        onClick: (setState) => {
          setState(true);
        },
      },
    ],
  }
];
export const nav = {
  presentation: navData[0],
};
