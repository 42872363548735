import { useContext } from "react";
import React, { Outlet } from "react-router-dom";
import { useAuth } from "../store/context/authContext";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

const BlankLayout = () => {

  const { isLogin } = useAuth();
  const token = localStorage.getItem('token')
  console.log('isLogin', isLogin)
  const location = useLocation();
  if (token) {
    return (<Navigate
      to="/PaperUpload/RecordList"
      state={{
        visitedLocation: location.pathname,
      }}
      replace
    />)
  }
  return (
    <main>
      <Outlet/>
    </main>
  );
};

export default BlankLayout;
