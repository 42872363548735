import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import { Grow } from "@mui/material";

const TransitionSlide = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const TransitionGrow = React.forwardRef(function Transition(props, ref) {
  return <Grow direction="left" ref={ref} {...props} />;
});

const Main = ({
  children,
  isOpen,
  handleClose,
  className,
  modalWidth = "lg",
  background,
  fullWidth,
  zIndex,
  overflow,
}) => {
  const style = {
    background: background
      ? background
      : "linear-gradient(112.83deg, rgba(255, 255, 255, 0.90) 0%, rgba(255, 255, 255, 0.553) 110.84%)",
    boxShadow:
      " 0px 2px 5.5px rgba(0, 0, 0, 0.02), inset 0px -4px 24px rgba(188, 192, 221, 0.25)",
    backdropFilter: "blur(21px)",
    border: " 1.5px solid #FFFFFF",
    borderRadius: "3px",
  };

  const sx = {
    zIndex: zIndex ? zIndex : 1300,
  };

  return (
    <Dialog
      fullWidth={fullWidth}
      className={className}
      maxWidth={modalWidth}
      open={isOpen || false}
      TransitionComponent={TransitionGrow}
      onClose={handleClose}
      PaperProps={{
        style: style,
      }}
      sx={sx}
      container={document.getElementById("modal")}
    >
      <DialogContent sx={{ overflow: overflow }}>{children}</DialogContent>
    </Dialog>
  );
};
Main.defaultProps = { fullWidth: true, overflowY:"hidden" };
export default Main;
