import { Box, Grid, Typography } from "@mui/material";
import React, { useState,useEffect } from "react";
import './filterBar.scss'
import SearchBar from "../searchBar/searchBar";
import Checkbox from '@mui/material/Checkbox';

const FilterBar = (props) => {
    const {
        search,
        check
        } = props

        const [isChecked,setIsChecked] = useState(false);
        
        const toggleCheckBox = ()=>{
            setIsChecked(!isChecked);
            check(!isChecked);
        }



    return (
        <Box style={{ width: '100%', padding: '20px 0px'}}>
            <Grid container >
                <Grid item xs={12} md={6} lg={3}>
                    <Typography variant="h3" className="label">Program</Typography>
                    <Box className="searchField">
                        <SearchBar placeholder={'Program'} handleChange={(e)=>search(e,'programName')} />
                    </Box>
                </Grid>

                <Grid item xs={12} md={6} lg={3}>
                    <Typography variant="h3" className="label">Level</Typography>
                    <Box className="searchField">
                    <SearchBar placeholder={'Level'} handleChange={(e)=>search(e,'levelName')} />
                    </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <Typography variant="h3" className="label">Subject</Typography>
                    <Box className="searchField">
                    <SearchBar placeholder={'Subject'} handleChange={(e)=>search(e,'subjectName')} />
                    </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={2}>
                    <Box className='row'>
                    <Checkbox style={{margin:0,padding:0,paddingRight:5}} value={isChecked} onChange={toggleCheckBox}/>
                    <Typography variant="h3" color={'black'}>Approved</Typography>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
};

export default FilterBar;
