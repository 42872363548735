import React, { useRef, useState } from "react";
import { Box, Button, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TextField, Typography } from "@mui/material";

import upload from "../../assets/images/upload.png";
import "./Upload.scss";
import { useForm } from "react-hook-form";
import InputFields from "../InputFields";

const Upload = (props) => {
  const { setRecord, recordList } = props
  const [paperType, setPaperType] = useState('')
  const [radioValue, setRadioValue] = useState('qp');

  const handleChange = (event) => {
    setRadioValue(event.target.value);
  };


  const defaultValues = useForm({
    defaultValues: {
      questionName: '',
      questionPDF: {},
      answerName: '',
      answerPDF: {},
    },
  });

  const {
    control,
    handleSubmit,
    setError,
    clearErrors,
    watch,
    reset,
    resetField,
    setValue,
    formState: { errors },
  } = defaultValues;

  const questionAssetRef = useRef();
  const answerAssetRef = useRef();
  const browseFileHandler = (e, type) => {
    setPaperType(type)
    if (type == 'question') {
      questionAssetRef.current.click();
    } else {
      answerAssetRef.current.click();
    }
  };
  const uploadImage = (e) => {
    const file = e.target.files[0];
    sendFormData(file);
  };
  const sendFormData = (file) => {
    console.log(file)
    const formData = new FormData();

    formData.append("file", file);
    formData.append("type", file?.type);
    if (paperType == 'question') {
      setValue('questionPDF', file);
    }
    console.log(paperType)
    if (paperType == 'answer') {
      setValue('answerPDF', file);
    }
  };
  const uploadOnDrop = (e, type) => {
    if (type) {
      setPaperType(type)
    }
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    sendFormData(file);
  };
  const DragOverHandler = (e) => {
    e.preventDefault();
    e.currentTarget.classList.add("dragOver");
  };
  const DragLeaveHandler = (e) => {
    e.currentTarget.classList.remove("dragOver");
  };

  const addInList = (e) => {
    console.log(e)
    console.log('answer pdf', e.questionPDF)
    if (radioValue == 'qp') {
      if (e.answerPDF instanceof File == false) {
        setError('answerName', {
          message: 'Please provide answer PDF.', // Provide a custom error message
        });
        return;
      }
    }
    if (e.questionPDF instanceof File == false) {
      setError('questionName', {
        message: 'Please provide question PDF.', // Provide a custom error message
      });
      return;
    }
    console.log(e)
    let arr = [...recordList]
    arr.push({ ...e, paperType: radioValue })
    console.log(arr, "record")
    setRecord(arr)
    reset();
  }
  return (
    <form style={{ display: 'flex' }}>
      <Grid container >
        <Grid item xs={12} sm={2} sx={{ display: 'flex', flex: 1, }}>
          <FormControl sx={{ display: 'flex', alignItems: 'center' }}>
            <FormLabel id="demo-controlled-radio-buttons-group">Select PDF Type:</FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={radioValue}
              onChange={handleChange}
            >
              <FormControlLabel value="qp" control={<Radio />} sx={{ margin: '0px' }} labelPlacement="bottom" label="QP" />
              <FormControlLabel value="er" control={<Radio />} sx={{ margin: '0px' }} labelPlacement="bottom" label="ER" />
              <FormControlLabel value="gt" control={<Radio />} sx={{ margin: '0px' }} labelPlacement="bottom" label="GT" />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={5} sx={{ display: 'flex', flex: 1, }}>

          <InputFields
            fieldName="questionName"
            type="text"
            label="Question"
            control={control}
            rules={{
              required: "Question Name & PDF is required",
            }}
            error={errors.questionName}
          />
          <div
            className="uploadArea"
            onClick={(e) => browseFileHandler(e, 'question')}
            onDragOver={DragOverHandler}
            onDragLeave={DragLeaveHandler}
            onDrop={(e) => uploadOnDrop(e, 'question')}
          >
            <input
              type="file"
              name="uploadAsset"
              id="uploadAsset"
              hidden
              onChange={uploadImage}
              ref={questionAssetRef}
            />
            <img src={upload} className="uploadImage" alt="upload" />


          </div>
        </Grid>
        {radioValue == 'qp' && <Grid item xs={12} sm={5} sx={{ display: 'flex', flex: 1 }}>
          <InputFields
            fieldName="answerName"
            type="text"
            label="Answer"
            control={control}
            rules={{
              required: "Answer Name and PDF is required",
            }}
            error={errors.answerName}
          />
          <div
            className="uploadArea"
            onClick={(e) => browseFileHandler(e, 'answer')}
            onDragOver={DragOverHandler}
            onDragLeave={DragLeaveHandler}
            onDrop={(e) => uploadOnDrop(e, 'answer')}
          >
            <input
              type="file"
              name="uploadAsset"
              id="uploadAsset"
              hidden
              onChange={uploadImage}
              ref={answerAssetRef}
            />
            <img src={upload} className="uploadImage" alt="upload" />
          </div>
        </Grid>}
        <Grid item xs={12} >
          <Box sx={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
            <Box>
              <Typography textAlign="center">
                {'drag & drop your question and answer files here and also give the name of these files'}
              </Typography>
              {recordList.length == 10 && <Typography  sx={{ marginTop: '10px' }}>
                {'Maximum limit of uploading pdf file is 10'}
              </Typography>}
            </Box>
            <Button variant="text" disabled={recordList.length == 10} onClick={handleSubmit(addInList)}>Add</Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};

export default Upload;