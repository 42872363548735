import React, { useState } from "react";
import { IconButton, Tooltip } from "@mui/material";

import { MdDelete, MdEdit } from "react-icons/md";
import DialogBox from "../dialog/DialogBox";
const RecordActionColumn = (props) => {
    const { onUpdate, deletePaper, isDialogOpen, setIsDialogOpen } = props
    // const [isDialogOpen, setIsDialogOpen] = useState(false)
    return (
        <>
            <Tooltip title="Delete">
                <IconButton size="small" onClick={deletePaper}>
                {/* <IconButton size="small" onClick={() => setIsDialogOpen(!isDialogOpen)}> */}
                    <MdDelete />
                </IconButton>
            </Tooltip>
            <Tooltip title="Edit" onClick={onUpdate}>
                <IconButton
                    size="small"
                >
                    <MdEdit />
                </IconButton>
            </Tooltip>
            <DialogBox
                isOpen={isDialogOpen}
                title="Do you want to delete this paper?"
                setIsOpen={setIsDialogOpen}
                delteUserHandler={deletePaper}
            />
        </>
    )

}
export default RecordActionColumn;