import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Avatar,
  Stack,
  Button,
  Grid,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormControl,
} from "@mui/material";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import Main from "../components/Main";
import "../styles/profile.scss";
import InputFields from "../components/InputFields";

import { useForm } from "react-hook-form";

const MyAccount = () => {

  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [password, setPassword] = useState({
    new: "",
    confirm: "",
  });
  const [showPassword, setShowPassword] = useState({
    current: false,
    new: false,
    reNew: false,
  });

  const handleChangeInfo = (e) => {
    setUserInfo((pre) => ({ ...pre, [e.target.name]: e.target.value }));
  };

  const handleClickShowPassword = (input) => {
    setShowPassword({ ...showPassword, [input]: !showPassword[input] });
  };

  const handleChangePassword = (e) => {
    setPassword((pre) => ({ ...pre, [e.target.name]: e.target.value }));
  };

  const defaultValues = useForm({
    defaultValues: {
      firstName:"",
      lastName:"",
      email:"",
      phone:""
    },
  });

  const {
    control,
    handleSubmit,
    setError,
    clearErrors,
    watch,
    formState: { errors },
  } = defaultValues;

  const passwordChange = () => {
    // if (password.new.trim().length < 8) {
    //   return dispatch(
    //     uiActions.showNotification({
    //       status: "error",
    //       title: "Invalid",
    //       message: "Invalid New Password",
    //     })
    //   );
    // } else if (password.confirm.trim().length < 8) {
    //   return dispatch(
    //     uiActions.showNotification({
    //       status: "error",
    //       title: "Invalid",
    //       message: "Invalid Confirm Password",
    //     })
    //   );
    // }

    // if (password.new !== password.confirm) {
    //   return dispatch(
    //     uiActions.showNotification({
    //       status: "error",
    //       title: "Invalid",
    //       message: "Both password are different.",
    //     })
    //   );
    // }

    // dispatch(changePassword({ password: password.new }));
    // setIsOpen(false);
  };

  const updateProfile = (event) =>{
    console.log(event)
  }
  return (
    <>
      <Main isOpen={isOpen} fullWidth={false}>
        <Stack width="20rem" gap={3}>
          <FormControl variant="outlined">
            <InputLabel>New Password</InputLabel>
            <OutlinedInput
              name="new"
              onChange={handleChangePassword}
              type={showPassword.new ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => handleClickShowPassword("new")}
                    edge="end"
                  >
                    {showPassword.new ? <MdVisibilityOff /> : <MdVisibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <FormControl variant="outlined">
            <InputLabel>Re-enter Password</InputLabel>
            <OutlinedInput
              name="confirm"
              onChange={handleChangePassword}
              type={showPassword.reNew ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => handleClickShowPassword("reNew")}
                    edge="end"
                  >
                    {showPassword.reNew ? (
                      <MdVisibilityOff />
                    ) : (
                      <MdVisibility />
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <Stack direction="row" justifyContent="flex-end">
            <Button
              onClick={() => {
                setIsOpen(false);
                setShowPassword({
                  current: false,
                  new: false,
                  reNew: false,
                });
              }}
            >
              Cancel
            </Button>
            <Button onClick={passwordChange}>Change</Button>
          </Stack>
        </Stack>
      </Main>
      <form>
      <Grid container className="profileContainer">
        <Grid className="profileMain" item xs={12}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={2}>
              <Avatar
                alt="Profile Photo"
                src="https://img.freepik.com/premium-vector/man-avatar-profile-round-icon_24640-14044.jpg?w=360"
              />
            </Grid>
             
              <>
                <Grid item xs={3}>
                  <InputFields
                  fieldName="firstName"
                  type="text"
                  label="FirstName"
                  control={control}
                  rules={{
                    required: "First Name is required",
                  }}
                  error={errors.firstName}
                />
                </Grid>
                <Grid item xs={3}>
                <InputFields
                  fieldName="lastName"
                  type="text"
                  label="LastName"
                  control={control}
                  rules={{
                    required: "Last Name is required",
                  }}
                  error={errors.lastName}
                />
                </Grid>

                
                
              </>
          </Grid>

          <Grid container spacing={2} alignItems="center">
            <Grid item xs={2}></Grid>
            <Grid item xs={3}>
                  <InputFields
                  fieldName="phone"
                  type="text"
                  label="Phone"
                  control={control}
                  rules={{
                    required: "Phone is required",
                  }}
                  error={errors.phone}
                />
                </Grid>
                <Grid item xs={3}>
                <InputFields
                  fieldName="email"
                  type="email"
                  label="Email"
                  control={control}
                  rules={{
                    pattern: {
                      value:
                        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/gi,
                      message: "Incorrect Email",
                    },
                    required: "Email is required",
                  }}
                  error={errors.email}
                />
                </Grid>
            <Grid item xs={3}>
              <Button variant="contained" onClick={() => setIsOpen(true)}>
                Change Password
              </Button>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            alignItems="center"
            className="fieldsContainer"
          >
            <Grid item xs={3}>
              <Button variant="contained" onClick={handleSubmit(updateProfile)}>
                Save
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      </form>
    </>
  );
};

export default MyAccount;
