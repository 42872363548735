import React, { Navigate, Route } from "react-router-dom";
import PageContent from "../layouts/PageContent";
import PaperUpload from "../screens/PaperUpload";
import RecordList from "../screens/RecordsLists";
import NotFound from "../layouts/NotFound";
import MyAccount from "../screens/MyAccount";
import DataApprovalList from "../screens/DataApprovalList";
import TeachersList from "../screens/TeachersList";
import BannerUpload from "../screens/BannerUpload";
import HomePageBannerUpload from "../screens/HomePageBannerUpload";
import ViewBanners from "../screens/ViewBanners";
import StudentList from "../screens/StudentList";
import UpdateBanner from "../screens/UpdateBanner";

export const privateRoutes = [
    { index: true, element: <Navigate to="PaperUpload/RecordList" /> },
    {
        path: "PaperUpload",
        element: <PageContent />,
        children: [
            { index: true, element: <Navigate to="RecordList" /> },
            {
                path: 'Recordlist',
                element: <RecordList />
            },
            {
                path: 'CreateForm',
                element: <PaperUpload />
            },
        ]
    },
    {
        path: 'teacherMaterial',
        element: <PageContent/>,
        children:[
            { index: true, element: <Navigate to="DataApprovalList" /> },
            {
                path: 'DataApprovalList',
                element: <DataApprovalList/>
            },
            {
                path: 'TeachersList',
                element: <TeachersList/>
            },
        ]
    },
    {
        path: "profile/my-account",
        element: <MyAccount />,
    },
    {
        path: "BannerUpload",
        element: <BannerUpload />,
    },
    {
        path: "HomePageBannerUpload",
        element: <HomePageBannerUpload />,
    },
    {
        path: "ViewBanners",
        element: <ViewBanners />,
    },
    {
        path: "UpdateBanner",
        element: <UpdateBanner />,
    },
    {
        path: "StudentList",
        element: <StudentList />,
    },
    { path: "*", element: <NotFound /> },
];