import { createSlice } from "@reduxjs/toolkit";

const DataListSlice = createSlice({
    name: "dataList",
    initialState: {
        programs:[],
        levels: [],
        subjects: [],
        years: [],
    },
    reducers: {
        setProgram: (state, action) => {
            state.programs = action.payload;
        },
        addProgram: (state, action) => {
            state.programs.push(action.payload)
        },
        updateProgram: (state, action) => {
            const getIndex = state.programs.findIndex(item => item.id == action.payload.id)
            state.programs[getIndex]= action.payload;
        },
        deleteProgram: (state, action) => {
            const getIndex = state.programs.findIndex(item => item.id == action.payload)
            state.programs.splice(getIndex, 1);
        },
        setLevel: (state, action) => {
            state.levels = action.payload;
        },
        addLevel: (state, action) => {
            state.levels.push(action.payload)
        },
        updateLevel: (state, action) => {
            const getIndex = state.levels.findIndex(item => item.id == action.payload.id)
            state.levels[getIndex]= action.payload;
        },
        deleteLevel: (state, action) => {
            const getIndex = state.levels.findIndex(item => item.id == action.payload)
            state.levels.splice(getIndex, 1);
        },
        setSubject: (state, action) => {
            state.subjects = action.payload;
        },
        addSubject: (state, action) => {
            state.subjects.push(action.payload)
        },
        updateSubject: (state, action) => {
            const getIndex = state.subjects.findIndex(item => item.id == action.payload.id)
            state.subjects[getIndex]= action.payload;
        },
        deleteSubject: (state, action) => {
            const getIndex = state.subjects.findIndex(item => item.id == action.payload)
            state.subjects.splice(getIndex, 1);
        },
        setYear: (state, action) => {
            state.years = action.payload;
        },
        addYear: (state, action) => {
            state.years.push(action.payload)
        },
        updateYear: (state, action) => {
            const getIndex = state.years.findIndex(item => item.id == action.payload.id)
            state.years[getIndex]= action.payload;
        },
        deleteYear: (state, action) => {
            const getIndex = state.years.findIndex(item => item.id == action.payload)
            state.years.splice(getIndex, 1);
        },
    },
});

export const setProgram = DataListSlice.actions.setProgram;
export const addProgram = DataListSlice.actions.addProgram;
export const updateProgram = DataListSlice.actions.updateProgram;
export const deleteProgram = DataListSlice.actions.deleteProgram;
export const setLevel = DataListSlice.actions.setLevel;
export const addLevel = DataListSlice.actions.addLevel;
export const updateLevel = DataListSlice.actions.updateLevel;
export const deleteLevel = DataListSlice.actions.deleteLevel;
export const setSubject = DataListSlice.actions.setSubject;
export const addSubject = DataListSlice.actions.addSubject;
export const updateSubject = DataListSlice.actions.updateSubject;
export const deleteSubject = DataListSlice.actions.deleteSubject;
export const setYear = DataListSlice.actions.setYear;
export const addYear = DataListSlice.actions.addYear;
export const updateYear = DataListSlice.actions.updateYear;
export const deleteYear = DataListSlice.actions.deleteYear;
export default DataListSlice.reducer;
