export const headCells = [
  {
    id: "levels",
    label: "Levels",
  },
  {
    id: "subjects",
    label: "Subjects",
  },
  {
    id: "topics",
    label: "Topics",
  },
  {
    id: "questions",
    label: "Questions",
  },
  {
    id: "answers",
    label: "Answers",
  },
];

const currentYear = new Date().getFullYear();
export const yearsList = Array.from({ length: 40 }, (_, index) => String(currentYear - index));

export const months = [
  'January', 'February', 'March', 'April', 'May', 'June',
  'July', 'August', 'September', 'October', 'November', 'December'
];

