import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Button,
} from '@mui/material';
import httpService from '../service/http.service';
import { api } from '../interfaces/api.interface';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';

const ViewBanners = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [banners, setBanners] = useState([]);

  const columns = [
    { field: 'title', headerName: 'Title', minWidth: 80},
    { field: 'programName', headerName: 'Program', minWidth: 100,flex:1  },
    { field: 'levelName', headerName: 'Level', minWidth: 100,flex:1  },
    { field: 'subjectName', headerName: 'Subject', minWidth: 100,flex:1  },
    { field: 'place', headerName: 'Place', minWidth: 50, },
    { field: 'createdOn', headerName: 'Created At', minWidth: 60  },
    { field: 'isActive', headerName: 'Active', minWidth: 40 },
    { field: 'totalBanners', headerName: 'Total Banners', minWidth: 40 },
    { field: 'id', headerName: 'Action', minWidth: 80, align:'center', renderCell:(params)=> {
    return <Button variant="contained" onClick={()=> navigate("/UpdateBanner", { state: params.value })}>View</Button>}}
  ];


  const navigate = useNavigate();
  
  useEffect(() => {
    setIsLoading(true);
    httpService
      .call(api.getBanners()) // Assuming you have an API endpoint to fetch the banners
      .then((res) => {
        if (res.status === 200) {
          console.log(res?.data?.data?.$values)
          setBanners(res?.data?.data?.$values);
        }
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    // <TableContainer component={Paper}>
    //   <Table sx={{ minWidth: 650 }} aria-label="simple table">
    //     <TableHead>
    //       <TableRow>
    //         <TableCell>Banner Name</TableCell>
    //         <TableCell>Link</TableCell>
    //         <TableCell>Image</TableCell>
    //       </TableRow>
    //     </TableHead>
    //     <TableBody>
    //       {banners.map((banner) => (
    //         <TableRow key={banner.id}>
    //           <TableCell component="th" scope="row">
    //             {banner.name}
    //           </TableCell>
    //           <TableCell>
    //             <a href={banner.link} target="_blank" rel="noopener noreferrer">
    //               {banner.link}
    //             </a>
    //           </TableCell>
    //           <TableCell>
    //             <img src={banner.image} alt={banner.name} style={{ width: '100px' }} />
    //           </TableCell>
    //         </TableRow>
    //       ))}
    //     </TableBody>
    //   </Table>
    // </TableContainer>
    <div style={{ height: 475, width: '100%' }}>
    <DataGrid
    loading={isLoading}
      rows={banners}
      sx={{
        "& .MuiDataGrid-columnHeaders": {
          fontSize:12,
        },
        "& .MuiDataGrid-row.Mui-odd": {
          backgroundColor:'#fff'
        },
        "& .MuiDataGrid-row.Mui-even": {
          backgroundColor:'#fff'
        },
        '& .MuiDataGrid-columnHeaderTitle': {
          fontWeight: 500,
          fontSize: '14px',
        },
        '& .MuiDataGrid-columnHeader':{
          backgroundColor:'black',
          color:'white',
          width:'100%'
        },
      }}
      columns={columns}
      initialState={{
        pagination: {
          paginationModel: { page: 0, pageSize: 7 },
        },
      }}
      pageSizeOptions={[7, 10]}
      disableRowSelectionOnClick
      getRowClassName={(params) =>
        params.indexRelativeToCurrentPage % 2 !== 0 ? 'Mui-even' : 'Mui-odd'
      }
      // onCellClick={(e)=>{ 
      //   if(e.value?.includes('http')){
      //     window.open(e.value, "_blank")}
      //   }}

      // slots={{ toolbar: GridToolbar }}
      // filterModel={filterModel}
      // onFilterModelChange={(newModel) => setFilterModel(newModel)}
      // slotProps={{ toolbar: { showQuickFilter: true } }}
      // columnVisibilityModel={columnVisibilityModel}
      // onColumnVisibilityModelChange={(newModel) =>
      //   setColumnVisibilityModel(newModel)
      // }
    />
  </div>
  );
};

export default ViewBanners;
