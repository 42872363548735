import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Logo from "../components/Logo";
import LogoLarge from "../assets/images/logo.jpeg";
import Copyright from "../components/Copyright";
import { Grid, Box, Typography } from "@mui/material";
import EmailForm from "../components/EmailForm";

const ForgotPassword = () => {
  const dispatch = useDispatch();

  const resetHandler = (e) => {
    // e.preventDefault();
    console.log(e)
    // let email = e.target.email.value;
    // dispatch(forgotPassword(email));
  };



  return (
    <Grid container className="login-page" spacing={2}>
      <Grid item md={7} sm={12}>
        <Box className="login-left">
          <Box id="login-background"></Box>
          {/* <img src={personImage} className="person-image" /> */}
        </Box>
      </Grid>
      <Grid
        item
        md={5}
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Box className="login-form-container">
          <Logo logoSrc={LogoLarge} />
          <Typography component="h1" variant="h1" className="login-heading">
            Forgot Your Password?
          </Typography>
          <EmailForm
            btnText="reset"
            backLink={true}
            submitHandler={resetHandler}
          />
          <Copyright />
        </Box>
      </Grid>
    </Grid>
  );
};
export default ForgotPassword;
