import { Box, Grid, Button } from "@mui/material";
import { Link } from "react-router-dom";
import InputFields from "./InputFields";
import { useForm } from "react-hook-form";
// import Input from "src/components/ui/formElements/Input";
const EmailForm = (props) => {

  const defaultValues = useForm({
    defaultValues: {
      email: "support@ser.com",
    },
  });

  const {
    control,
    handleSubmit,
    setError,
    clearErrors,
    watch,
    formState: { errors },
  } = defaultValues;
  return (
    <form onSubmit={handleSubmit(props.submitHandler)}>
      <Box className="loginForm">
        <Box className="ControlWrap">
          <InputFields
            fieldName="email"
            type="email"
            label="Email"
            control={control}
            rules={{
              pattern: {
                value:
                  /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/gi,
                message: "Incorrect Email",
              },
              required: "Email is required",
            }}
            error={errors.email}
          />
        </Box>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item></Grid>
          {props.backLink && (
            <Grid item>
              <Link className="link-txt" to="/login">
                Back to Login
              </Link>
            </Grid>
          )}
        </Grid>
        <Box className="pt-4">
          <Button type="submit" variant="contained" color="btnColor" fullWidth sx={{ letterSpacing: "1.2px" }}>
            {props.btnText}
          </Button>
        </Box>
      </Box>
    </form>
  );
};
export default EmailForm;
