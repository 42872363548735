
import { configureStore } from "@reduxjs/toolkit";
import DataListSlice from "./dataList-slice";
import uiSlice from "./ui-slice";

const Store = configureStore({
  reducer: {
    ui: uiSlice,
    DataList: DataListSlice,
  },
});
export default Store;
