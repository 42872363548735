import React, { useContext } from "react";
import { IconButton, Tooltip } from "@mui/material";
import { MdAdd, MdEdit, MdDelete } from "react-icons/md";
import { FieldSelectorContext } from "../../store/context/fieldSelectore";
import DropDown from "../dropDown/DropDown";
import { IoEllipsisVerticalSharp } from "react-icons/io5";

const FieldEdit = (props) => {
    const { cell } = props

    const fieldCtxt = useContext(FieldSelectorContext)

    const fieldHandler = (action) => {
        fieldCtxt.fieldSelectorHandler({ cell: cell.id, action })
    }
    const dropData = [
        {
            label: "Add New",
            icon: <MdAdd />,
            onClick: (id) => {
                fieldHandler('add')
            },
        },
        {
            label: "Edit",
            icon: <MdEdit />,
            onClick: (id) => {
                fieldHandler('edit')
            },
        },
        {
            label: "Delete",
            icon: <MdDelete />,
            onClick: (id) => {
                fieldHandler('delete')
            },
        },
    ]
    return (
        <DropDown
            id={props.id}
            tooltip="More"
            dropIcon={<IoEllipsisVerticalSharp />}
            items={dropData}
        />
    )

}
export default FieldEdit;