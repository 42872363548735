import React, { useState } from "react";
import { Box, Collapse, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import SelectedPdfFiles from "../selectedPdfFiles/selectedPdfFiles";
import RecordActionColumn from "../recordActionColumn/recordActionColumn";
import { useNavigate } from "react-router-dom";
import httpService from "../../service/http.service";
import { api } from "../../interfaces/api.interface";

const Tables = (props) => {
    const { setRows, items, rows, extraColumnName, extraColumn, getPapersList } = props
    const [isExpand, setIsExpand] = useState(null)
    const [isDialogOpen, setIsDialogOpen] = useState(false)
    const navigate = useNavigate();

    const expandHandler = (index) => {
        if (isExpand == index) {
            setIsExpand(null)
        } else {
            setIsExpand(index)
        }

    }

    const updateHandler = (data) => {
        console.log(data)
        navigate("/PaperUpload/CreateForm",)
    }

    const deletePaper = (data, index) => {
        httpService.call(api.deletePaperDetail(data.id)).then(res => {
            console.log(rows)
            setIsDialogOpen(false)
            setRows([])
            getPapersList()
            // let updatedValue = rows.map(item => ({
            //     ...item,
            //     papersList: item.papersList?.$values.filter(obj => obj.id !== data.id) || []
            // }));
            // console.log(updatedValue)

        })
        // let findIndex = rows.map(item => {
        //     const filteredValues = item.papersList.$values.filter(obj => obj.id === data.id);
        //     return filteredValues.length > 0 ? index : -1;
        // }).findIndex(i => i == 1)

        // let obj = {...rows}
        // obj[findIndex]={...obj, papersList:{
        //     $values:rows[findIndex].papersList.$values.splice(1,1)
        // }}

        // setRows(updatedValue)
        // console.log(findIndex, "index")
    }

    return (
        <TableContainer >
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        {
                            items.map((cell, index) => (
                                <TableCell
                                    sx={{
                                        textOverflow: "ellipsis",
                                        fontWeight: 900,
                                        fontSize: "14px",
                                    }}
                                    key={cell.id} align="center" >
                                    {cell}
                                </TableCell>
                            ))
                        }
                        {extraColumnName && <TableCell
                            sx={{
                                textOverflow: "ellipsis",
                                fontWeight: 900,
                                fontSize: "14px",
                            }}
                            align="center">{extraColumnName}</TableCell>}
                    </TableRow>
                </TableHead>
                <TableBody>

                    {rows.map((row, index) => (
                        <>
                            <TableRow
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                onClick={expandHandler.bind(this, index)}
                            >
                                {
                                    items.map((cell, index) => {
                                        return (
                                            <TableCell align="center" >
                                                {row[cell.toLowerCase() + "Name"]}
                                            </TableCell>
                                        )
                                    })
                                }
                                <TableCell align="center" >
                                    {extraColumn(row)}
                                    {/* <RecordActionColumn
                                        onUpdate={() => updateHandler(row)}
                                        deletePaper={() => deletePaper(rows,row)}
                                        isDialogOpen={isDialogOpen}
                                        setIsDialogOpen={setIsDialogOpen} /> */}
                                </TableCell>

                            </TableRow>

                            {isExpand == index && row.papersList.$values.length > 0 &&
                                <TableRow>
                                    <TableCell colSpan={6} align="center">
                                        <SelectedPdfFiles list={row.papersList.$values} remove={deletePaper} isDialogOpen={isDialogOpen} setIsDialogOpen={setIsDialogOpen} />
                                    </TableCell>
                                </TableRow>
                            }
                        </>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )

}
export default Tables;