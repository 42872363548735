import React, { useState, useEffect } from 'react';
// import './teacherDashboard.scss';


//MUI Imports
import {Typography,Box,CircularProgress} from '@mui/material';
import CustomizedTable from '../components/customizedTable';


//Verrtical Tabs
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import httpService from '../service/http.service';
import { api } from '../interfaces/api.interface';
import { renderActionsCell, renderBooleanCell } from '@mui/x-data-grid';

import TextField from '@mui/material/TextField';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import FilterBar from '../components/filterBar/filterBar';
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}



const ConfirmationModal = ({open,closeHandler,onAccept})=> {
  return(
      <Dialog
        open={open}
        onClose={closeHandler}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Do you want to approve this?"}
        </DialogTitle>
        <DialogActions>
          <Button onClick={closeHandler}>Disagree</Button>
          <Button onClick={onAccept} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
  )
}
 function DataTabs() {

  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = React.useState(0);
  const [allVideos,setAllVideos] = useState([]);
  const [allNotes,setAllNotes] = useState([]);
  const [filteredNotes,setFilteredNotes] = useState([]);
  const [filteredVideos,setFilteredVideos] = useState([]);
  const [videoModal, setVideoModal] = React.useState(false);
  const [notesModal, setNotesModal] = React.useState(false);
  const [rowID,setRowID] = useState();

  const showVideoModal = (params) => {
    setVideoModal(true);
    setRowID(params?.row?.$id)
    console.log("working",params?.row?.$id)
  };

  const hideVideoModal = () => {
    setVideoModal(false);
  };

  const showNotesModal = (params) => {
    setNotesModal(true)
    setRowID(params?.row?.id)
    console.log("working",params?.row?.id)

  };

  const hideNotesModal = () => {
    setNotesModal(false);
  };

  const onAcceptHandler = (type)=> {
    type == "notes" ? hideNotesModal() : hideVideoModal()
    approveData(rowID,type)
  }



  const getAllNotes = () => {
    setIsLoading(true)
    httpService.call(
      api.getNotesApproval(),null,true)
      .then(res => {
      if (res?.status === 200) {
        setIsLoading()
        setAllNotes(res.data.data.teachersViews["$values"])
        setFilteredNotes(res.data.data.teachersViews["$values"])
        console.log("Notes====", res.data.data.teachersViews["$values"])
      }
      setIsLoading(false)

    }).catch((e) => {
      console.log(e)
      setIsLoading(false)

    })
  }
 
  
  const getAllVideos = () => {
    setIsLoading(true)
    httpService.call(
      api.getVideosApproval(),null,true
    ).then(res => {
      if (res?.status === 200) {
        setAllVideos(res.data.data.teachersViews["$values"])
        setFilteredVideos(res.data.data.teachersViews["$values"])
        console.log("Videos====", res.data.data.teachersViews["$values"])
      }
    setIsLoading(false)
    }).catch((e) => {
      console.log(e)
     setIsLoading(false)

    })
  }


  const approveData = (id,type) => {
    setLoading(true);
    httpService.call(
      api.approveData(id),null,true)
      .then(res => {
      if (res?.status === 200) {
        console.log("Approved==>",res)
        type == "notes" ? getAllNotes() : getAllVideos()
      }
      setLoading(false);
    }).catch((e) => {
      console.log(e)
      setLoading(false);
    })
  }
 


  const searchNotes = (text,key) => {
    const filterList = filteredNotes.filter((data)=> data?.[key]?.toLowerCase()?.includes(text));
    if(text.length > 0){
      console.log(text,filterList)
      setAllNotes(filterList) 
    }else{
      setAllNotes(filteredNotes)
      console.log(text,filterList)
    }
  }


  const approvedNotes = (value) => {
    const filterList = filteredNotes.filter((data)=> data?.isApproved == true );    
    if (value){
      setAllNotes(filterList)
    }else{
      setAllNotes(filteredNotes)
    }
  }

  const approvedVideos = (value) => {
    const filterList = filteredVideos.filter((data)=> data?.isApproved == true );    
    if (value){
      setAllVideos(filterList)
    }else{
      setAllVideos(filteredVideos)
    }
  }

  
  const searchVideos = (text,key) => {
    const filterList = filteredVideos.filter((data)=> data?.[key]?.toLowerCase()?.includes(text));
    if(text.length > 0){
      console.log(text,filterList)
      setAllVideos(filterList) 
    }else{
      setAllVideos(filteredVideos)
      console.log(text,filterList)
    }
  }


  useEffect(()=> {
    getAllNotes();
    getAllVideos();
  },[]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  const genericColumn = [
    { field: 'programName', headerName: 'Program', minWidth: 100,flex:1  },
    { field: 'levelName', headerName: 'Level', minWidth: 80},
    { field: 'subjectName', headerName: 'Subject', minWidth: 80,flex:1},
    { field: 'monthName', headerName: 'Month', minWidth: 80},
    { field: 'yearName', headerName: 'Year', minWidth: 80},
  ]
  const videColumns = [
    ...genericColumn,
    { field: 'videoName', headerName: 'Video', minWidth: 100},
    { field: 'videoUrl', headerName: 'URL', minWidth: 100,flex:1  },
    { field: 'isApproved', headerName: 'Approved', minWidth: 80, align:'center', renderCell:(params)=> {
    return params.value  ?  <Button variant="contained" disabled>Approved</Button>  :  <Button variant="contained" onClick={()=>showVideoModal(params)}>Approve</Button>}}
  ];

  const notesColumns = [
    ...genericColumn,
    { field: 'notesName', headerName: 'Note', minWidth: 100 },
    { field: 'notesPdf', headerName: 'PDF', minWidth: 100,flex:1 },
    { field: 'isApproved', headerName: 'Approved', minWidth: 80,align:'center',renderCell:(params)=> {
      return params.value  ? <Button variant="contained" disabled>Approved</Button>  :  <Button variant="contained" onClick={()=>showNotesModal(params)}>Approve</Button>}}
  ]

  return (
    <Box>
      <Box>
        <Tabs 
        TabIndicatorProps={{
            style: {
              backgroundColor: "#03494B",
              color: "#03494B",
            }
          }}
          textColor="#03494B"
          centered
          
          sx={{ display:'flex',alignItems:'center',justifyContent:'center'}}
        value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Notes" style={{fontSize:18}} {...a11yProps(0)} />
          <Tab label="Videos" style={{fontSize:18}} {...a11yProps(1)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>

          <FilterBar search={searchNotes} check={approvedNotes} />
          { notesModal && <ConfirmationModal open={showNotesModal} closeHandler={hideNotesModal}  onAccept={()=>onAcceptHandler('notes')}/> }
      <CustomizedTable data={allNotes} columns={notesColumns} loading={isLoading}/>
     
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        
      { videoModal && <ConfirmationModal open={showVideoModal} closeHandler={hideVideoModal} onAccept={()=>onAcceptHandler('video')}/> }
      <FilterBar search={searchVideos} check={approvedVideos} />
      <CustomizedTable data={allVideos} columns={videColumns} loading={isLoading}/>

      </CustomTabPanel>      
    </Box>
  );
}

const DataApprovalList  = ()=> {


  return(
    <DataTabs/>
  )
}

export default DataApprovalList;
