import React, { useEffect, useState, useContext } from "react";
import { Card, CircularProgress, Grid, Stack, Typography } from "@mui/material";
import Button from "../components/Button";
import { Box } from "@mui/material";
import "../styles/paperUpload.css";
import { useForm } from "react-hook-form";
import SelectFields from "../components/SelectFields";
import Upload from "../components/upload/Upload";
import FieldEdit from "../components/fieldEdit/fieldEdit";
import httpService from "../service/http.service";
import { api, apiURL } from "../interfaces/api.interface";
import { useDispatch, useSelector } from "react-redux";
import { setLevel, setProgram, setSubject, setYear } from "../store/redux/dataList-slice";
import { months, yearsList } from "../constant";
import RightDrawer from "../components/rightDrawer/rightDrawer";
import FieldHandler from "./FieldHandler";
import { FieldSelectorContext } from "../store/context/fieldSelectore";
import SelectedPdfFiles from "../components/selectedPdfFiles/selectedPdfFiles";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { uiActions } from "../store/redux/ui-slice";

const PaperUpload = () => {
  const fieldCtxt = useContext(FieldSelectorContext)
  const location = useLocation();
  const navigate = useNavigate();
  const [postPaperDetails, setPostPaperDetails] = useState([])
  const [listError, setListError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isCreateNew, setIsCreateNew] = useState(false)

  const dispatch = useDispatch()
  const { programs, levels, subjects } = useSelector(state => state.DataList)

  const defaultValues = useForm({
    defaultValues: {
      program: "",
      level: "",
      subject: "",
      year: "",
      month: "",
    },
  });

  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = defaultValues;

  useEffect(() => {
    if (location.state?.id) {
      const { programId, levelId, subjectId, yearName, monthName } = location?.state
      console.log(programId, levelId, subjectId, yearName, monthName)
      setValue("program", programId)
      setValue("level", levelId)
      setValue("subject", subjectId)
      setValue("year", yearName)
      setValue("month", monthName)
    }
  }, []);

  useEffect(() => {
    setIsCreateNew(false)
    console.log(isCreateNew, "in dependents")
  }, [fieldCtxt.fieldSelector])

  useEffect(() => {
    getPrograms()
    getLevels()
    getSubjects()
  }, [])

  const getPrograms = () => {
    httpService.call(
      api.programs()
    ).then(res => {
      if (res.status === 200) {
        dispatch(setProgram(res.data.data["$values"]))
      }
    }).catch((e) => {
      console.log(e)
    })
  }
  const getLevels = () => {
    httpService.call(
      api.level()
    ).then(res => {
      if (res?.status === 200) {
        dispatch(setLevel(res.data.data["$values"]))
      }
    }).catch((e) => {
      console.log(e)
    })
  }
  const getSubjects = () => {
    httpService.call(
      api.subject()
    ).then(res => {
      if (res?.status === 200) {
        dispatch(setSubject(res.data.data["$values"]))
      }
    }).catch((e) => {
      console.log(e)
    })
  }

  useEffect(() => {
    setListError(false)
  }, [postPaperDetails])

  const onSubmitHandler = (data) => {
    if (postPaperDetails.length === 0 && !location.state?.id) {
      setListError(true)
      return
    }
    setIsLoading(true)
    const formData = new FormData();

    if (location.state?.id) {
      formData.append("Id", location.state?.id);
    }
    formData.append("SubjectId", data.subject);
    formData.append("LevelId", data.level);
    formData.append("YearName", String(data.year));
    formData.append("MonthName", String(data.month));
    formData.append("ProgramId", data.program);
    formData.append("PaperType", "QP")
    if (postPaperDetails.length > 0) {
      postPaperDetails.forEach((e, i) => {
        formData.append(`PostPaperDetails[${i}].questionName`, e.questionName)
        formData.append(`PostPaperDetails[${i}].questionPDF`, e.questionPDF);
        if (e.paperType === 'qp') {
          formData.append(`PostPaperDetails[${i}].answerName`, e.answerName)
          formData.append(`PostPaperDetails[${i}].answerPDF`, e.answerPDF);
        }
        formData.append(`PostPaperDetails[${i}].paperType`, e.paperType);
      })
    } else {
      formData.append(`PostPaperDetails`, [])
    }
    let method =location.state?.id ?  api.updatePaper().method : api.postPaper().method
    try {
      axios[method](`https://serapi.serprep.com/api/Papers`, formData)
        .then(res => {
          setIsLoading(false)
          if (res.data.succeeded) {
            dispatch(
              uiActions.showNotification({
                status: "success",
                title: "Authorized!",
                message: `The paper has been ${location.state?.id?'update':'added'}.`,
              })
            )
            reset()
            setPostPaperDetails([])
            navigate(-1)
          }
        })
    } catch (e) {
      setIsLoading(false)
      console.log(e)
    }
  }

  const removeFile = (i) => {
    let arr = [...postPaperDetails]
    arr.splice(i, 1)
    setPostPaperDetails(arr)
  }

  return (
    <form style={{ display: 'flex' }}>
      <Grid container spacing={1}>
        <Grid item xs={12} >
          <Card className="form-card">
            <Box className="form-heading">
              <Typography variant="h1">Course Details:</Typography>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4} className="feildInput">
                <SelectFields
                  fieldName="program"
                  label="Progam"
                  control={control}

                  options={programs.map((pg) => {
                    return { label: pg.programName, value: pg.id };
                  })}
                  rules={{
                    required: "Program is required",
                  }}
                  error={errors?.program}
                  withSearch
                />
                {<FieldEdit cell={{
                  id: "programs",
                  label: "Programs",
                }} />}
              </Grid>
              <Grid item xs={12} sm={4} className="feildInput">
                <SelectFields
                  fieldName="level"
                  label="Level"
                  control={control}
                  disabled={!watch("program")}
                  options={levels.filter(e => e.programId == watch("program")).map((lvl) => {
                    return { label: lvl.levelName, value: lvl.id };
                  })}
                  rules={{
                    required: "Level is required",
                  }}
                  error={errors?.level}
                  withSearch
                />
                {<FieldEdit cell={{
                  id: "levels",
                  label: "Levels",
                }} />}
              </Grid>

              <Grid item xs={12} sm={4} className="feildInput">
                <SelectFields
                  fieldName="subject"
                  label="Subject"
                  control={control}
                  disabled={!watch("level")}
                  options={subjects.filter(e => e.levelId == watch("level")).map((sub) => {
                    return { label: sub.subjectName, value: sub.id };
                  })}
                  rules={{
                    required: "Subject is required",
                  }}
                  error={errors?.subject}
                  withSearch
                />
                {<FieldEdit cell={{
                  id: "subjects",
                  label: "Subjects",
                }} />}
              </Grid>

              <Grid item xs={12} sm={6} className="feildInput">
                <SelectFields
                  fieldName="year"
                  label="Year"
                  control={control}
                  options={yearsList.map((y) => {
                    return { label: y, value: y };
                  })}
                  rules={{
                    required: "Year is required",
                  }}
                  error={errors?.year}
                  withSearch
                />
              </Grid>
              <Grid item xs={12} sm={6} className="feildInput">
                <SelectFields
                  fieldName="month"
                  label="Month"
                  control={control}
                  options={months.map((m) => {
                    return { label: m, value: m };
                  })}
                  rules={{
                    required: "Month is required",
                  }}
                  error={errors?.month}
                  withSearch
                />
              </Grid>

              {/* <Grid item xs={12} sm={9} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', }}>
                <Upload recordList={postPaperDetails} setRecord={setPostPaperDetails} />
                {listError && <Typography textAlign="center" variant="body2" color='error'>
                  Please provide Question and Answer paper!
                </Typography>}
              </Grid> */}
            </Grid>


            <Box className="form-heading">
              <Typography variant="h1">Upload Files:</Typography>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', }}>
                <Upload recordList={postPaperDetails} setRecord={setPostPaperDetails} />
                {listError && <Typography textAlign="center" variant="body2" color='error'>
                  Please provide Question and Answer paper!
                </Typography>}
              </Grid>
            </Grid>
            {
              postPaperDetails.length > 0 && <SelectedPdfFiles list={postPaperDetails} remove={removeFile} show />
            }

            <Stack direction="row" justifyContent="flex-end">
              <Button
                onClick={handleSubmit(onSubmitHandler)}
                type="submit"
                color="btnColor"
                variant="contained"
                disabled={isLoading}
              >
                {isLoading && (
                  <CircularProgress size={20} sx={{ marginRight: 1 }} />
                )}
                Save
              </Button>
            </Stack>

          </Card>
        </Grid>

      </Grid>
      <RightDrawer>
        {fieldCtxt.fieldSelector && <FieldHandler />}
      </RightDrawer>
    </form>
  );
};

export default PaperUpload;