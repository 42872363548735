import React, { useEffect, useState, useRef } from "react";
import { Card, CircularProgress, Grid, Stack, Typography, RadioGroup, FormControlLabel, Radio, Table, TableBody, TableCell, TableHead, TableRow, IconButton, TableContainer, Paper, Switch } from "@mui/material";
import Button from "../components/Button";
import { Box } from "@mui/material";
import "../styles/paperUpload.css";
import { useForm, Controller } from "react-hook-form";
import SelectFields from "../components/SelectFields";
import httpService from "../service/http.service";
import { api } from "../interfaces/api.interface";
import { useDispatch, useSelector } from "react-redux";
import { setLevel, setProgram, setSubject } from "../store/redux/dataList-slice";
import InputFields from "../components/InputFields";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, TimePicker, TimeField } from "@mui/x-date-pickers";
import upload from "../assets/images/upload.png";
import { uiActions } from "../store/redux/ui-slice";
import EditIcon from '@mui/icons-material/Edit'; 

import dayjs from "dayjs";
import objectSupport from 'dayjs/plugin/objectSupport'
import utc from 'dayjs/plugin/utc'

const BannerDetails = ({data,setData,bannerId}) => {

  // states
  const [banners, setBanners] = useState([]);

  // date and time
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);

  // Edit Index
  const [editIndex, setEditIndex] = useState(null);

  // ref
  const imageRef = useRef();
  
  // Third Party 
  dayjs.extend(objectSupport)
  dayjs.extend(utc)

const dispatch = useDispatch()
  // Set Data to local state
  useEffect(()=>{
    console.log(data)
    if(data){
      setBanners(data)
    }
  },[data])


  const browseFileHandler = (e) => {
    imageRef?.current?.click();
  };

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  const handleFileChange = (e) => {
    let file = e.target.files[0];
    getBase64(file)
      .then((result) => {
        file["base64"] = result;
        const imageFile = {
          data: result,
          name: file?.name,
          extension: file?.type,
        };
        setValue("image", result);
        setValue("imageName", file?.name);
      })
      .catch((err) => {
        console.log(err);
      });
  };


  const defaultValues = useForm({
    defaultValues: {
      name: "",
      image:"",
      link: "",
      imageName:"",
      fromDate:"",
      toDate:"",
      startTime:"",
      endTime:""
    },
  });

  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    resetField,
    formState: { errors },
  } = defaultValues;

  const resetAll = ()=> {
      reset()
      setFromDate(null);
      setToDate(null);
      setStartTime(null);
      setEndTime(null);
  }

  const updateBanner = (data) => {
    if (data?.image?.includes('http')){
      data.image = null;
      console.log("true")
    }
    data.id = editIndex

    console.log(data);
    httpService
      .call(api.updateBanners(), data)
      .then((res) => {
      if (res.data.succeeded) {
          const updatedBanners = [...banners];
          console.log("data ==>", res.data)
      
      if (editIndex !== null) {
        const index =updatedBanners.findIndex(x => x.id == editIndex)
        updatedBanners[index] = res?.data?.data;
        setEditIndex(null);
        setBanners(updatedBanners);
        setData(updatedBanners)
        
          dispatch(
            uiActions.showNotification({
              status: "success",
              title: "Uploaded Successfully!",
              message: "Banner uploaded successfully",
            })
          );
          resetAll();
        } else {
          console.log("Upload API ==>", res);
        }
      }
})
      .catch((e) => {
        console.log(e);
      });
  };


  const addBanner = (data) => {
    
    httpService
      .call(api.addSingleBanner(), data)
      .then((res) => {
        if (res.data.succeeded) {
            const updatedBanners = [...banners,data];
            setBanners(updatedBanners);
            setData(updatedBanners)

            dispatch(
            uiActions.showNotification({
              status: "success",
              title: "Uploaded Successfully!",
              message: "Banner uploaded successfully",
            })
          );
          resetAll();
        } else {
          console.log("Upload API ==>", res);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };


  const deleteBanner = (id) => {
    httpService
      .call(api.deleteSingleBanner(id))
      .then((res) => {
        if (res?.data?.succeeded) {
          console.log("Delete ==>", res.data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  // Add Banner Handler
  const addBannerHandler = () => {    
    const data = {
      name: watch("name"),
      image: watch("image"),
      link: watch("link"),
      startDate: watch("fromDate"),
      endDate: watch("toDate"),
      startTime: startTime?.format("HH:mm:ss"),
      endTime: endTime?.format("HH:mm:ss"),
      isActive: true,
      imageName:watch("imageName"),
      bannerDetailId: bannerId
    };

    if (editIndex !== null) {
        updateBanner(data)
      } else {
        addBanner(data)
      }
  };


  // Edit Banner Handler
  const editBannerHandler = (index) => {
    const banner = banners.find(x=> x.id == index)
    console.log("banner -=>", banner)
    const sTime = (([h, m, s]) => ({ h, m, s }))(banner.startTime.split(':').map(Number));
    const eTime = (([h, m, s]) => ({ h, m, s }))(banner.endTime.split(':').map(Number));  

    setEditIndex(index);
    setValue("name", banner.title || banner.name);
    setValue("image", banner.image);
    setValue("link", banner.link);
    setValue("fromDate", banner.startDate);
    setValue("toDate", banner.endDate);
    setValue("startTime", dayjs(sTime));
    setValue("endTime", dayjs(eTime));
    setStartTime(dayjs(sTime));
    setEndTime(dayjs(eTime))
    setFromDate(banner.startDate);
    setToDate(banner.endDate);

    setValue("imageName", banner.imageName || `Image${index+1}`);
  };


  return (
      <Grid container spacing={1}>
        <Grid item xs={12}>

            <Box className="form-heading">
              <Typography variant="h1">Banner Details:</Typography>
            </Box>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={4} className="feildInput">
                <InputFields
                  fieldName="name"
                  type="text"
                  label="Name"
                  control={control}
                  rules={{
                    required: "Banner name is required",
                  }}
                  error={errors?.name}
                />
              </Grid>

              <Grid item xs={12} sm={4} className="feildInput">
                <InputFields
                  fieldName="link"
                  type="text"
                  label="Link"
                  control={control}
                  rules={{
                    required: "Link is required",
                  }}
                  error={errors?.link}
                />
              </Grid>

              <Grid item xs={12} sm={4} className="feildInput">
                <InputFields
                  disabled={true}
                  fieldName="imageName"
                  label="Image"
                  control={control}
                  rules={{
                    required: "Image is required",
                  }}
                  error={errors?.imageName}
                />
                <div className="uploadArea" onClick={(e) => browseFileHandler(e)}>
                  <input type="file" name="uploadAsset" id="uploadAsset" hidden onChange={handleFileChange} ref={imageRef} />
                  <img src={upload} className="uploadImage" alt="upload" />
                </div>
              </Grid>

              <Grid item xs={12} sm={3} className="feildInput">
                <Controller
                  name="fromDate"
                  control={control}
                  rules={{ required: "From date is required" }}
                  render={({ field }) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="From"
                        {...field}
                        value={dayjs(fromDate)}
                        onChange={(date) => {
                          setFromDate(date);
                          field.onChange(date);
                        }}
                        sx={{width:'100%'}}
                        slotProps={{
                          textField: {
                            error: !!errors?.fromDate,
                            helperText: errors?.fromDate?.message ,
                          },
                        }}

                      />
                    </LocalizationProvider>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={3} className="feildInput">
                <Controller
                  name="toDate"
                  control={control}
                  rules={{ required: "To date is required" }}
                  render={({ field }) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="To"
                        {...field}
                        value={dayjs(toDate)}
                        onChange={(date) => {
                          setToDate(date);
                          field.onChange(date);
                        }}
                        sx={{width:'100%'}}
                        slotProps={{
                          textField: {
                            error: !!errors?.toDate,
                            helperText: errors?.toDate?.message ,
                          },
                        }}
                      />
                    </LocalizationProvider>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={3} className="feildInput">
                <Controller
                  name="startTime"
                  control={control}
                  rules={{ required: "Start time is required" }}
                  render={({ field }) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="EN">
                      <TimeField
                        label="Start Time"
                        value={startTime}
                        format='HH:mm:ss'
                        timezone="system"
                        sx={{width:"100%"}}
                        onChange={(time) => {
                          setStartTime(time);
                          field.onChange(time);
                        }}
                        slotProps={{
                          textField: {
                            error: !!errors?.startTime,
                            helperText: errors?.startTime?.message ,
                          },
                        }}
                      />
                    </LocalizationProvider>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={3} className="feildInput">
                <Controller
                  name="endTime"
                  control={control}
                  rules={{ required: "End time is required" }}
                  render={({ field }) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimeField
                        label="End Time"
                        value={endTime}
                        format='HH:mm:ss'
                        timezone="system"
                        sx={{width:"100%"}}
                        onChange={(time) => {
                          setEndTime(time);
                          field.onChange(time);
                        }}
                        slotProps={{
                          textField: {
                            error: !!errors?.endTime,
                            helperText: errors?.endTime?.message ,
                          },
                        }}
                      />
                    </LocalizationProvider>
                  )}
                />
              </Grid>

            </Grid>
            <Stack direction="row" justifyContent="flex-start" sx={{ mt: 2,gap:2 }}>
              <Button
                onClick={ handleSubmit( addBannerHandler )}
                color="btnColor"
                variant="contained"
              >
               {editIndex != null ? "Update Banner" :"Add Banner"}
              </Button>
               { editIndex != null &&  <Button
                onClick={()=>{
                  resetAll()
                  setEditIndex(null);
                }}
                color="btnColor"
                variant="outlined"
              >
               Cancel
              </Button>}
            </Stack>

            {banners?.length > 0 && (
    <Box sx={{ mt: 4 }}>
    <Paper>
      <TableContainer>
        <Table>
        <TableHead>
            <TableRow sx={{ backgroundColor: '#f5f5f5' }}>
              <TableCell>
                <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                  Banner Name
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                  Link
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                  From Date
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                  To Date
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                  Start Time
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                  End Time
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                  Image
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                  Active
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                  Edit
                </Typography>
              </TableCell>

            </TableRow>
          </TableHead>            
          <TableBody>
            {banners.map((banner, index) => (
              <TableRow key={index} hover>
                <TableCell>{banner?.title || banner?.name}</TableCell>
                <TableCell>{banner.link}</TableCell>
                <TableCell>{dayjs(banner.startDate)?.format('DD/MM/YYYY')}</TableCell>
                <TableCell>{dayjs(banner.endDate)?.format('DD/MM/YYYY')}</TableCell>
                <TableCell>{banner.startTime}</TableCell>
                <TableCell>{banner.endTime}</TableCell>
                <TableCell>
                  <img src={banner?.image} style={{ width: 50, height: 30 }} alt="Banner" />
                </TableCell>
                <TableCell>
                  <Switch
                    checked={banner?.isActive}
                    onChange={()=>deleteBanner(banner?.id)}
                    inputProps={{ 'aria-label': 'controlled' }}/>
                 </TableCell>
                 <TableCell>
                 <IconButton onClick={() => editBannerHandler(banner.id)}>
                    <EditIcon />
                  </IconButton>
                 </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  </Box>
          )}


        </Grid>
      </Grid>
  );
};

export default BannerDetails;
