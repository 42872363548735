import React, { useState, useRef } from "react";
import { Card, CircularProgress, Grid, Stack, Typography } from "@mui/material";
import Button from "../components/Button";
import { Box } from "@mui/material";
import { useForm } from "react-hook-form";
import InputFields from "../components/InputFields";

import upload from "../assets/images/upload.png";
import { uiActions } from "../store/redux/ui-slice";
import httpService from "../service/http.service";
import { api } from "../interfaces/api.interface";
import { useDispatch } from "react-redux";

const HomePageBannerUpload = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [imageName, setImageName] = useState("");

  const imageRef = useRef();

  const browseFileHandler = (e) => {
    imageRef?.current?.click();
  };

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
    });
  };

  const handleFileChange = (e) => {
    let file = e.target.files[0];
    getBase64(file)
      .then((result) => {
        setImageFile(result);
        setImageName(file?.name);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const dispatch = useDispatch();

  const defaultValues = useForm({
    defaultValues: {
      bannerName: "",
      link: "",
    },
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = defaultValues;

  const onSubmitHandler = (data) => {
    let params = {
      name: data?.bannerName,
      link: data?.link,
      image: imageFile,
    };

    setIsLoading(true);
    httpService
      .call(api.uploadHomePageBanners(), params)
      .then((res) => {
        if (res.data.succeeded) {
          setIsLoading(false);
          dispatch(
            uiActions.showNotification({
              status: "success",
              title: "Uploaded Successfully!",
              message: "Banner uploaded successfully",
            })
          );
          reset();
          setImageName("");
          setImageFile("");
        } else {
          console.log("Upload failed:", res);
          setIsLoading(false);
        }
      })
      .catch((e) => {
        console.error("Error uploading banner:", e);
        setIsLoading(false);
      });
  };

  return (
    <form style={{ display: "flex" }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card className="form-card">
            <Box className="form-heading">
              <Typography variant="h1">Homepage Banner Upload</Typography>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <InputFields
                  fieldName="bannerName"
                  type="text"
                  label="Name"
                  control={control}
                  rules={{
                    required: "Banner name is required",
                  }}
                  error={errors?.bannerName}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputFields
                  fieldName="link"
                  type="text"
                  label="Link"
                  control={control}
                  rules={{
                    required: "Link is required",
                  }}
                  error={errors?.link}
                />
              </Grid>
              <Grid item xs={12} sm={12} >
                <InputFields
                  disabled={true}
                  fieldName="image"
                  type="text"
                  label="Image"
                  control={control}
                  value={imageName}
                  error={errors?.image}
                />
                <div className="uploadArea" onClick={(e) => browseFileHandler(e)} style={{alignItems:'center',gap:5}}>
                  <input type="file" name="uploadAsset" id="uploadAsset" hidden onChange={handleFileChange} ref={imageRef} />
                  <img src={upload} className="uploadImage" alt="upload" variant='h2' />
                  <Typography>Upload</Typography>
                </div>
              </Grid>
            </Grid>
            <Stack direction="row" justifyContent="flex-end">
              <Button
                onClick={handleSubmit(onSubmitHandler)}
                type="submit"
                color="btnColor"
                variant="contained"
                disabled={isLoading}
              >
                {isLoading && <CircularProgress size={20} sx={{ marginRight: 1 }} />}
                Save
              </Button>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </form>
  );
};

export default HomePageBannerUpload;
