import { useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import InputFields from "../components/InputFields";

const LoginForm = (props) => {
  // const [email, setEmail] = useState("");
  // const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const forgotHandler = () => { };

  const defaultValues = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const {
    control,
    handleSubmit,
    setError,
    clearErrors,
    watch,
    formState: { errors },
  } = defaultValues;

  const email = watch("email");
  const password = watch("password");


  const isValid = () => {
    return email && password;
  };
  return (
    <form>
      <Box className="loginForm">
        <Typography variant="h6" className="sign-in-text">
          Please Sign in with your account
        </Typography>
        <Box className="ControlWrap">
          <InputFields
            fieldName="email"
            type="email"
            label="Email"
            control={control}
            rules={{
              pattern: {
                value:
                  /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/gi,
                message: "Incorrect Email",
              },
              required: "Email is required",
            }}
            error={errors.email}
          />
        </Box>
        <Box className="ControlWrap">
          <InputFields
            fieldName="password"
            type={showPassword ? "text" : "password"}
            label="Password"
            control={control}
            error={errors.password}
            inputProps={{
              endAdornment: (
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
                </IconButton>
              ),
            }}
          />
        </Box>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          className="login-row-container"
        >
          <Grid item>
            <Typography
              component="p"
              className="link-txt"
              onClick={forgotHandler}
            >
              <Link to="/forgot-password">Forgot Password?</Link>
            </Typography>
          </Grid>
        </Grid>
        <Box className="pt-4">
          <Button
            type="submit"
            variant="contained"
            color="btnColor"
            onClick={handleSubmit(props.loginHandler)}
            fullWidth
            sx={{ letterSpacing: "1.2px" }}
            disabled={!isValid() || props.isLoading}
          >
            {props.isLoading && (
              <CircularProgress size={20} sx={{ marginRight: 1 }} />
            )}
            SIGN IN
          </Button>
        </Box>
      </Box>
    </form>
  );
};
export default LoginForm;
