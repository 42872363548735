import React,{useState} from 'react';
import { DataGrid,GridAddIcon,GridToolbar } from '@mui/x-data-grid';



export default function CustomizedTable({data,columns,loading}) {
  const [filterModel, setFilterModel] = React.useState({
    items: [],
    quickFilterExcludeHiddenColumns: true,
    quickFilterValues: [],
  });

  return (
    <div style={{ height: 475, width: '100%' }}>
      <DataGrid
      loading={loading}
        rows={data}
        sx={{
          "& .MuiDataGrid-columnHeaders": {
            fontSize:12,
          },
          "& .MuiDataGrid-row.Mui-odd": {
            backgroundColor:'#fff'
          },
          "& .MuiDataGrid-row.Mui-even": {
            backgroundColor:'#fff'
          },
          '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: 500,
            fontSize: '14px',
          },
          '& .MuiDataGrid-columnHeader':{
            backgroundColor:'black',
            color:'white',
            width:'100%'
          },
        }}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 7 },
          },
        }}
        pageSizeOptions={[7, 10]}
        disableRowSelectionOnClick
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 !== 0 ? 'Mui-even' : 'Mui-odd'
        }
        // onCellClick={(e)=>{ 
        //   if(e.value?.includes('http')){
        //     window.open(e.value, "_blank")}
        //   }}

        slots={{ toolbar: GridToolbar }}
        filterModel={filterModel}
        onFilterModelChange={(newModel) => setFilterModel(newModel)}
        slotProps={{ toolbar: { showQuickFilter: true } }}
        // columnVisibilityModel={columnVisibilityModel}
        // onColumnVisibilityModelChange={(newModel) =>
        //   setColumnVisibilityModel(newModel)
        // }
      />
    </div>
  );
}
