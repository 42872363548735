import React, { useEffect, useState, useRef } from "react";
import { Card, CircularProgress, Grid, Stack, Typography, RadioGroup, FormControlLabel, Radio, Table, TableBody, TableCell, TableHead, TableRow, IconButton } from "@mui/material";
import Button from "../components/Button";
import { Box } from "@mui/material";
import "../styles/paperUpload.css";
import { useForm, Controller } from "react-hook-form";
import SelectFields from "../components/SelectFields";
import httpService from "../service/http.service";
import { api } from "../interfaces/api.interface";
import { useDispatch, useSelector } from "react-redux";
import { setLevel, setProgram, setSubject } from "../store/redux/dataList-slice";
import InputFields from "../components/InputFields";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, TimePicker, TimeField } from "@mui/x-date-pickers";
import upload from "../assets/images/upload.png";
import { uiActions } from "../store/redux/ui-slice";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit'; // Import EditIcon

import dayjs from "dayjs";
import objectSupport from 'dayjs/plugin/objectSupport'
import utc from 'dayjs/plugin/utc'
import BannersTable from "./Table/BannersTable";

const CourseDetail = ({data,setData,bannerId}) => {

  // states
  const [imageFile, setImageFile] = useState(null);
  const [banners, setBanners] = useState([]);

  // date and time
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);

  // Edit Index
  const [editIndex, setEditIndex] = useState(null);

  // ref
  const imageRef = useRef();
  
  // Third Party 
  dayjs.extend(objectSupport)
  dayjs.extend(utc)


  // Set Data to local state
  useEffect(()=>{
    console.log(data)
    if(data){
      setBanners(data)
    }
  },[data])


  const browseFileHandler = (e) => {
    imageRef?.current?.click();
  };

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  const handleFileChange = (e) => {
    let file = e.target.files[0];
    getBase64(file)
      .then((result) => {
        file["base64"] = result;
        const imageFile = {
          data: result,
          name: file?.name,
          extension: file?.type,
        };
        setValue("image", result);
        setValue("imageName", file?.name);
        console.log("image", result)
      })
      .catch((err) => {
        console.log(err);
      });
  };


  const defaultValues = useForm({
    defaultValues: {
      name: "",
      image:"",
      link: "",
      imageName:"",
      fromDate:"",
      toDate:"",
      startTime:"",
      endTime:""
    },
  });

  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    resetField,
    formState: { errors },
  } = defaultValues;

  const resetAll = ()=> {
      reset()
      setFromDate(null);
      setToDate(null);
      setStartTime(null);
      setEndTime(null);
  }

  
  // Add Banner Handler
  const addBannerHandler = () => {    
      const banner = {
        name: watch("name"),
        image: watch("image"),
        link: watch("link"),
        startDate: watch("fromDate"),
        endDate: watch("toDate"),
        startTime: startTime?.format("HH:mm:ss"),
        endTime: endTime?.format("HH:mm:ss"),
        isActive: true,
        imageName:watch("imageName")
      };

      if (editIndex !== null && bannerId) {
        // updatedBanners[editIndex] = banner;
        setEditIndex(null);
      }
      
      const updatedBanners = [...banners];
      
      if (editIndex !== null) {
        updatedBanners[editIndex] = banner;
        setEditIndex(null);
      } else {
        updatedBanners.push(banner);
      }
      setBanners(updatedBanners);
      setData(updatedBanners)
      resetAll()  
  };

  // Remove Banner Handler
  const removeBannerHandler = (index) => {
    const updatedBanners = banners.filter((_, i) => i !== index);
    setBanners(updatedBanners);
    setData(updatedBanners)

  };

  // Edit Banner Handler
  const editBannerHandler = (index) => {
    const banner = banners[index];
    const sTime = (([h, m, s]) => ({ h, m, s }))(banner.startTime.split(':').map(Number));
    const eTime = (([h, m, s]) => ({ h, m, s }))(banner.endTime.split(':').map(Number));  

    setEditIndex(index);
    setValue("name", banner.title || banner.name);
    setValue("image", banner.image);
    setValue("link", banner.link);
    setValue("fromDate", banner.startDate);
    setValue("toDate", banner.endDate);
    setValue("startTime", dayjs(sTime));
    setValue("endTime", dayjs(eTime));
    setStartTime(dayjs(sTime));
    setEndTime(dayjs(eTime))
    setFromDate(banner.startDate);
    setToDate(banner.endDate);

    setValue("imageName", banner.imageName || `Image${index+1}`);
  };


  return (
      <Grid container spacing={1}>
        <Grid item xs={12}>

            <Box className="form-heading">
              <Typography variant="h1">Banner Details:</Typography>
            </Box>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={4} className="feildInput">
                <InputFields
                  fieldName="name"
                  type="text"
                  label="Name"
                  control={control}
                  rules={{
                    required: "Banner name is required",
                  }}
                  error={errors?.name}
                />
              </Grid>

              <Grid item xs={12} sm={4} className="feildInput">
                <InputFields
                  fieldName="link"
                  type="text"
                  label="Link"
                  control={control}
                  rules={{
                    required: "Link is required",
                  }}
                  error={errors?.link}
                />
              </Grid>

              <Grid item xs={12} sm={4} className="feildInput">
                <InputFields
                  disabled={true}
                  fieldName="imageName"
                  label="Image"
                  control={control}
                  rules={{
                    required: "Image is required",
                  }}
                  error={errors?.imageName}
                />
                <div className="uploadArea" onClick={(e) => browseFileHandler(e)}>
                  <input type="file" name="uploadAsset" id="uploadAsset" hidden onChange={handleFileChange} ref={imageRef} />
                  <img src={upload} className="uploadImage" alt="upload" />
                </div>
              </Grid>

              <Grid item xs={12} sm={3} className="feildInput">
                <Controller
                  name="fromDate"
                  control={control}
                  rules={{ required: "From date is required" }}
                  render={({ field }) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="From"
                        {...field}
                        value={dayjs(fromDate)}
                        onChange={(date) => {
                          setFromDate(date);
                          field.onChange(date);
                        }}
                        sx={{width:'100%'}}
                        slotProps={{
                          textField: {
                            error: !!errors?.fromDate,
                            helperText: errors?.fromDate?.message ,
                          },
                        }}

                      />
                    </LocalizationProvider>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={3} className="feildInput">
                <Controller
                  name="toDate"
                  control={control}
                  rules={{ required: "To date is required" }}
                  render={({ field }) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="To"
                        {...field}
                        value={dayjs(toDate)}
                        onChange={(date) => {
                          setToDate(date);
                          field.onChange(date);
                        }}
                        sx={{width:'100%'}}
                        slotProps={{
                          textField: {
                            error: !!errors?.toDate,
                            helperText: errors?.toDate?.message ,
                          },
                        }}
                      />
                    </LocalizationProvider>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={3} className="feildInput">
                <Controller
                  name="startTime"
                  control={control}
                  rules={{ required: "Start time is required" }}
                  render={({ field }) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="EN">
                      <TimeField
                        label="Start Time"
                        value={startTime}
                        format='HH:mm:ss'
                        timezone="system"
                        sx={{width:"100%"}}
                        onChange={(time) => {
                          setStartTime(time);
                          field.onChange(time);
                        }}
                        slotProps={{
                          textField: {
                            error: !!errors?.startTime,
                            helperText: errors?.startTime?.message ,
                          },
                        }}
                      />
                    </LocalizationProvider>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={3} className="feildInput">
                <Controller
                  name="endTime"
                  control={control}
                  rules={{ required: "End time is required" }}
                  render={({ field }) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimeField
                        label="End Time"
                        value={endTime}
                        format='HH:mm:ss'
                        timezone="system"
                        sx={{width:"100%"}}
                        onChange={(time) => {
                          setEndTime(time);
                          field.onChange(time);
                        }}
                        slotProps={{
                          textField: {
                            error: !!errors?.endTime,
                            helperText: errors?.endTime?.message ,
                          },
                        }}
                      />
                    </LocalizationProvider>
                  )}
                />
              </Grid>

            </Grid>
            <Stack direction="row" justifyContent="flex-start" sx={{ mt: 2,gap:2 }}>
              <Button
                onClick={ handleSubmit(addBannerHandler)}
                color="btnColor"
                variant="contained"
              >
               {editIndex != null ? "Update Banner" :"Add Banner"}
              </Button>
               { editIndex != null &&  <Button
                onClick={()=>{
                  resetAll()
                  setEditIndex(null);
                }}
                color="btnColor"
                variant="outlined"
              >
               Cancel
              </Button>}
            </Stack>

            {banners?.length > 0 && (
              <BannersTable banners={banners} 
              editBannerHandler={editBannerHandler} 
              removeBannerHandler={removeBannerHandler} 
              />
            )}


        </Grid>
      </Grid>
  );
};

export default CourseDetail;
