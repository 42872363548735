import React, { createContext, useContext, useEffect, useState } from "react";
import Cookies from "js-cookie";
// import cookie from "react-cookies";

// Global sequence number, incremented every time a query is run
export const AuthContext = createContext(null);

const AuthProvider = ({ children }) => {
  const [authUser, setAuthUser] = useState(null);
  useEffect(()=>{
    const isUser = localStorage.getItem('user');
    console.log("isUser==>",isUser)
    setAuthUser(isUser)
  },[])

  const authenticate = data => {
    setAuthUser(data);
    console.log("data==>",data?.token)
    
    localStorage.setItem('token',data?.token)
    localStorage.setItem('user',JSON.stringify(data))
    
  };

  const logout = () => {
    setAuthUser(null);
    localStorage.clear()  
  };

  const value = {
    token: authUser?.token,
    authUser:authUser,
    authenticate: authenticate,
    logout: logout,
    isLogin: !!authUser?.token,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
export const useAuth = () => {
    return useContext(AuthContext);
  };
  
