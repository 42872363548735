import { TextField } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import { onlyPhone } from "../utils/Utils";
const InputFields = ({ error, type, fieldName, control, rules, label, phone, disabled,inputProps, ...rest }) => {
  
  return (
    <Controller
      name={fieldName}
      control={control}
      rules={rules}
      render={({ field }) => {
        return (
          <TextField
          className="outlined"
            required={(rules?.required)  && true}
            variant="outlined"
            error={error && true}
            helperText={error ? error.message : " "}
            type={type}
            inputRef={field.ref}
            label={label}
            value={phone? onlyPhone(field.value) : field.value}
            onChange={field.onChange}
            fullWidth
            disabled = {disabled}
            autoComplete=""
            InputProps={inputProps}
            {...rest}
          />
        );
      }}
    />
  );
};

export default InputFields;
