import React from "react";
import "../styles/PageContent.scss";
import Card from "../components/card/Card";
import { Outlet } from "react-router-dom";
const PageContent = (props) => {
  return (
        <Card classes="screen">
          <Outlet />
        </Card>
  );
};

export default PageContent;