import React, {useState, useCallback } from "react";
import {
  Navigate,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import {
  Grid,
  Box,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  Breadcrumbs,
  Tooltip,
  Button,
} from "@mui/material";
import {
  IoPeopleCircle,
  IoLogOutOutline,
} from "react-icons/io5";
import personAvatart from "../../assets/images/person-avatar.jpeg";
import "./Header.scss";
import { AiOutlineMenu } from "react-icons/ai";
import cookie from "react-cookies";

const Header = ({ menuToggleHandler }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);

  const pathNames = location.pathname
    .split("/")
    .slice(1)
    .filter((x) => !parseInt(x));

  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = (path) => {
    setAnchorEl(null);
    handleMobileMenuClose();
    // navigate(path);
  };


  const logoutHandler = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
    // cookie.remove("serAdmin", { path: "/" });
    localStorage.clear()
    navigate("/login");
    // cookie.remove("serAdmin")
    // return (<Navigate
    //   to="/login"
    //   state={{
    //     visitedLocation: location.pathname,
    //   }}
    //   replace
    // />)
    // dispatch(logout());
  };

  const handleProfileNavigate = () => {
    navigate("/profile/my-account");
    handleMenuClose();
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      classes={{ list: "header-person-details-list" }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
    >
      <Box className="header-person-details-container">
        <Box className="header-person-image">
          <img src={personAvatart} alt="" width="30" />
        </Box>
        <Box className="header-person-details">
          <h6>
            {"Dummy"} {"User"}
          </h6>
          <p>{"abc@xyz.com"}</p>
          <Button variant="outlined" onClick={handleProfileNavigate}>
            Manage Your Account
          </Button>
        </Box>
      </Box>
      <MenuItem onClick={logoutHandler}>
        <IoLogOutOutline size="22" color="#2cc1d6" />
        Sign Out
      </MenuItem>
    </Menu>
  );

  return (
    <>
      {/* <Guide open={showGuide} closeHandler={closeGuideHandler} /> */}
      <Box sx={{ flexGrow: 0 }} className="desktopHeader">
        <AppBar position="static">
          <Toolbar sx={{ padding: "0px !Important" }}>
            <Grid container spacing={1} alignItems="center">
              <Grid
                item
                className="breadcrumb-text"
              >
                {pathNames[0].includes("campaign") ? (
                  <Breadcrumbs aria-label="breadcrumb">
                    {pathNames.map((item, index) => (
                      <Typography
                        variant="h6"
                        noWrap
                        component="p"
                        sx={{
                          display: {
                            xs: "none",
                            sm: "block",
                          },
                        }}
                        key={item + index}
                      >
                        {item}
                      </Typography>
                    ))}
                  </Breadcrumbs>
                ) : (
                  <Box className="toggleButton">
                    <IconButton onClick={() => {
                      menuToggleHandler()
                    }} size="small">
                      <AiOutlineMenu color="white"></AiOutlineMenu>
                    </IconButton>
                    <Typography
                      variant="h1"

                      component="h1"
                      sx={{ display: { xs: "none", sm: "block" } }}
                    >
                      {pathNames[0]}
                    </Typography>
                  </Box>
                )}
              </Grid>
            </Grid>

            <Box sx={{ display: { md: "flex" } }} marginLeft="10px">

              <Tooltip title={"userData.email"}>
                <IconButton
                  size="medium"
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                  color="inherit"
                  className="user-icon-header"
                >
                  <IoPeopleCircle />
                </IconButton>
              </Tooltip>
            </Box>
          </Toolbar>
        </AppBar>

        {renderMenu}
      </Box>
    </>
  );
};
export default Header;
