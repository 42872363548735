import React, { useContext, useEffect } from "react"
import { Box, Drawer } from "@mui/material";
import { FieldSelectorContext } from "../../store/context/fieldSelectore";
import './rightDrawer.css'
import useMediaQuery from '@mui/material/useMediaQuery';

const RightDrawer = (props) => {
  const isMobile = useMediaQuery('(max-width:600px)');
  const { children } = props;
  const [isOpen, setIsOpen] = React.useState(false);

  const fieldCtxt = useContext(FieldSelectorContext)
  useEffect(() => {
    if (fieldCtxt.fieldSelector) {
      setIsOpen(!isOpen)
    }
  }, [fieldCtxt.fieldSelector])

  const toggleDrawer = () => {
    setIsOpen(!isOpen)
    fieldCtxt.clearFieldSelector()
  }

  return (
    <Drawer
      anchor={'right'}
      open={isOpen}
      onClose={toggleDrawer}
      PaperProps={{
        className: isMobile ? "mobileView" : "mainContainer"
      }}
    >
      <Box sx={{background:'#eef3ff', margin:'16px', padding:'16px', borderRadius: '4px'}}>
      {children}
      </Box>
    </Drawer>
  );
};
export default React.memo(RightDrawer);
